export const allTimezones = [
    {"label": "Pacific Time Zone", "value": "Pacific Time Zone"},
    {"label": "Mountain Time Zone", "value": "Mountain Time Zone"},
    {"label": "Central Time Zone", "value": "Central Time Zone"},
    {"label": "Eastern Time Zone", "value": "Eastern Time Zone"}
  ];  

  export const possibleObjectives = [
    {
        "name": "Discover the best channel of communication",
        "emoji": "💬",
        "description": "Identify the most effective communication medium for engaging with customers, whether it's through social media, email, phone calls, or in-person discussions."
    },
    {
        "name": "Schedule a visit to the dealership",
        "emoji": "👨‍💼",
        "description": "Encourage potential customers to book an appointment to visit the dealership, offering them a personalized experience and a chance to view vehicles firsthand."
    },
    {
        "name": "Gather information about current car",
        "emoji": "🚗",
        "description": "Collect details about the customer's current vehicle to understand their needs and preferences, which can aid in suggesting suitable new or used car options."
    },
    {
        "name": "Explore customer's interests",
        "emoji": "🛍️",
        "description": "Investigate the specific interests and requirements of the customer, such as vehicle type, features, and budget, to provide tailored recommendations."
    },
    {
        "name": "Check proximity to the dealership",
        "emoji": "📍",
        "description": "Determine the customer's location in relation to the dealership to offer location-specific services, promotions, and convenience in vehicle servicing and visits."
    },
    {
        "name": "Schedule vehicles service appointment",
        "emoji": "⚙️",
        "description": "Facilitate the booking of service appointments for customers' vehicles, ensuring timely maintenance and repair for optimal vehicle performance."
    }
]

export const voicesList = [
  {
    "id": "wp6TnKGZkElPHVWcy1rP",
    "label": "Alex - Expressive Narrator",
    "previewUrl": "voice_preview_Alex - expressive narrator.mp3"
  },
  {
    "id": "glzZeb1qBAicNYqMPObo",
    "label": "Noah - Calm",
    "previewUrl": "voice_preview_Noah - Calm.mp3"
  },
  {
    "id": "oZa7dhmEVAxHodN4rQsD",
    "label": "Kwame",
    "previewUrl": "voice_preview_Kwame.mp3"
  },
  {
    "id": "PZA814dkJtipEJwIm7pQ",
    "label": "Jennifer - Expressive and Cheerful",
    "previewUrl": "voice_preview_Jennifer - expressive and cheerful narrator.mp3"
  },
  {
    "id": "kWd0MsDr9SHF343HUOLt",
    "label": "Tamika",
    "previewUrl": "voice_preview_Tamika.mp3"
  },
  {
    "id": "06oPEcZqPWhZ2IeTcOJc",
    "label": "Joe Yang",
    "previewUrl": "voice_preview_Joe Yang.mp3"
  },
  {
    "id": "1fywkWuaxbcAf7QbV8Vg",
    "label": "Lanorre - Thoughtful and Confident",
    "previewUrl": "voice_preview_Lanorre - Thoughtful and Confident.mp3"
  },
  {
    "id": "WMAep4VNx7tpmwqc1u9x",
    "label": "Markus - Mature and Chill",
    "previewUrl": "voice_preview_Markus - Mature and Chill.mp3"
  },
  {
    "id": "nFVnUQBxCzygphIqF25d",
    "label": "Scarlett",
    "previewUrl": "voice_preview_Scarlett.mp3"
  },
  {
    "id": "jIKJxnFvudIroHUUmlG2",
    "label": "Natasia - Snarky and Mature",
    "previewUrl": "voice_preview_Natasia - Snarky and Mature.mp3"
  },
  {
    "id": "zIxDnGtj6IzAkP4KJ2Oo",
    "label": "Rob",
    "previewUrl": "voice_preview_Rob.mp3"
  }
];

export const ALL_STATES = [
  {"value": "AL", "label": "Alabama"},
  {"value": "AK", "label": "Alaska"},
  {"value": "AZ", "label": "Arizona"},
  {"value": "AR", "label": "Arkansas"},
  {"value": "CA", "label": "California"},
  {"value": "CO", "label": "Colorado"},
  {"value": "CT", "label": "Connecticut"},
  {"value": "DC", "label": "District of Columbia"},
  {"value": "DE", "label": "Delaware"},
  {"value": "FL", "label": "Florida"},
  {"value": "GA", "label": "Georgia"},
  {"value": "HI", "label": "Hawaii"},
  {"value": "ID", "label": "Idaho"},
  {"value": "IL", "label": "Illinois"},
  {"value": "IN", "label": "Indiana"},
  {"value": "IA", "label": "Iowa"},
  {"value": "KS", "label": "Kansas"},
  {"value": "KY", "label": "Kentucky"},
  {"value": "LA", "label": "Louisiana"},
  {"value": "ME", "label": "Maine"},
  {"value": "MD", "label": "Maryland"},
  {"value": "MA", "label": "Massachusetts"},
  {"value": "MI", "label": "Michigan"},
  {"value": "MN", "label": "Minnesota"},
  {"value": "MS", "label": "Mississippi"},
  {"value": "MO", "label": "Missouri"},
  {"value": "MT", "label": "Montana"},
  {"value": "NE", "label": "Nebraska"},
  {"value": "NV", "label": "Nevada"},
  {"value": "NH", "label": "New Hampshire"},
  {"value": "NJ", "label": "New Jersey"},
  {"value": "NM", "label": "New Mexico"},
  {"value": "NY", "label": "New York"},
  {"value": "NC", "label": "North Carolina"},
  {"value": "ND", "label": "North Dakota"},
  {"value": "OH", "label": "Ohio"},
  {"value": "OK", "label": "Oklahoma"},
  {"value": "OR", "label": "Oregon"},
  {"value": "PA", "label": "Pennsylvania"},
  {"value": "RI", "label": "Rhode Island"},
  {"value": "SC", "label": "South Carolina"},
  {"value": "SD", "label": "South Dakota"},
  {"value": "TN", "label": "Tennessee"},
  {"value": "TX", "label": "Texas"},
  {"value": "UT", "label": "Utah"},
  {"value": "VT", "label": "Vermont"},
  {"value": "VA", "label": "Virginia"},
  {"value": "WA", "label": "Washington"},
  {"value": "WV", "label": "West Virginia"},
  {"value": "WI", "label": "Wisconsin"},
  {"value": "WY", "label": "Wyoming"}
]

export const standardGuidelines = [
  "Begin each interaction with a polite greeting to establish a professional tone.",
  "Maintain a balance of friendliness and professionalism throughout the conversation.",
  "Avoid overly casual language or slang.",
  "Be clear and concise with information, respecting the customer's time without rushing them.",
  "Adapt to the customer's responses, ensuring the conversation flows smoothly and remains engaging.",
  "Keep messages succinct yet comprehensive, conveying warmth and helpfulness.",
  "Listen attentively to the customer's needs and offer flexible visit options that suit their schedule.",
];

export const steps = [
  { title: 'First', description: 'Attendant Info' },
  { title: 'Second', description: 'Objectives' },
  { title: "Third", description: 'Instructions'},
  { title: "Fourth", description: 'Inventory'}
]

export function capitalize(str) {
  if (!str) return '';
  return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}

export const newProductPlaceholder = "Brand new 2023 Toyota Highlander with the model number '6951'. It's a 4D Sport Utility vehicle that comes with an 8-Speed Automatic transmission and is of the XLE series. The vehicle has 4 doors and is powered by a 2.4L I4 PDI Turbocharged DOHC 16V LEV3-SULEV30 engine with 4 cylinders and an engine displacement of 2.4. The vehicle features a front-wheel drive (FWD) drivetrain. Its exterior color is 'Magnetic Gray Metallic', and the interior is adorned in Graphite. The vehicle is priced at $43,052.00, with an invoice and MSRP value of $44,923.00 each. It was added to the inventory on October 12, 2023. This Highlander is not certified. The vehicle's features include 6 speakers, AM/FM radio with SiriusXM, among others. The engine description emphasizes its 2.4L I4 PDI Turbocharged specifications, and it runs on gasoline."