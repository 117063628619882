import { useEffect, useState } from "react";
import { ref as refStorage, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

const AppointmentRecording = ({
  call,
}: {
  call: {
    id: string;
    audioUrl?: string;
  };
}) => {
  const [recordingUrl, setRecordingUrl] = useState<string | undefined>(
    call?.audioUrl
  );

  useEffect(() => {
    if (!call.audioUrl) {
      const audioRef = refStorage(storage, `recordings/${call.id}.mp3`);
      getDownloadURL(audioRef)
        .then((url) => {
          setRecordingUrl(url);
        })
        .catch((error) => {
          setRecordingUrl(undefined);
        });
    }
  }, [call]);

  const onLoadedMetadata = (event) => {
    if (event.target.duration < 10) {
      setRecordingUrl(undefined);
    }
  };

  if (recordingUrl === undefined) {
    return null;
  }

  return (
    <audio controls onLoadedMetadata={onLoadedMetadata} style={{width: '100%'}}>
      <source src={recordingUrl} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  )
};

export default AppointmentRecording;
