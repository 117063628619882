import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { isProduction } from "./utils";

const firebaseConfig = {
  staging: {
    apiKey: "AIzaSyCm3RkCxfZzxczhmyP9LRSO4_U1ssmeKW8",
    authDomain: "call-converter-staging.firebaseapp.com",
    projectId: "call-converter-staging",
    storageBucket: "call-converter-staging.appspot.com",
    messagingSenderId: "437429997385",
    appId: "1:437429997385:web:43c805c4101c1fee8d9498",
    measurementId: "G-MG5ETXW5X8",
  },
  production: {
    apiKey: "AIzaSyDa0AzurozIQMoOUmfKyYLCUEzkr0dsi-I",
    authDomain: "call-converter-702c9.firebaseapp.com",
    databaseURL: "https://call-converter-702c9-default-rtdb.firebaseio.com",
    projectId: "call-converter-702c9",
    storageBucket: "call-converter-702c9.appspot.com",
    messagingSenderId: "269679789177",
    appId: "1:269679789177:web:02f4efef167ade6293c0a8",
    measurementId: "G-H2HRGLRD37",
  },
};

// Initialize Firebase
const app = initializeApp(
  isProduction() ? firebaseConfig.production : firebaseConfig.staging
);

const analytics = getAnalytics(app);
export const db = getDatabase(app);
export const firestoreDb = getFirestore(app);
// connectFirestoreEmulator(firestoreDb, '127.0.0.1', 8080);
export const auth = getAuth(app);
export const storage = getStorage(app);
