import { fetcher } from '../utils/api/fetcher';

export default async function fetchGenerateAudio(text:string, voice:string) {
    const body = {
        text,
        voice
    }
    const response = await fetcher(`/generate_audio`, {
        method: 'POST',
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error('Failed to create audio');
    }

    return response;
}
