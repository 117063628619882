import { WrapItem, Divider, Flex, Badge, Box, Image, Text } from "@chakra-ui/react";
import { CampaignCardInterface } from "../utils/interfaces";

export default function CampaignCard({allCampaigns, campaignId, setHoveredProject, hoveredProject, navigate}: CampaignCardInterface) {
    return (
        <WrapItem 
            key={campaignId}
            onMouseEnter={() => setHoveredProject(campaignId)}
            onMouseLeave={() => setHoveredProject('')}
        >
            <Box
                onClick={() => navigate('/campaign?id=' + campaignId)}
                opacity={hoveredProject === campaignId ? '0.85' : '1'}
                transform={hoveredProject === campaignId ? 'scale(1.05)' : 'none'}
                cursor="pointer"
                borderRadius="15px"
                border="1px solid #E2E8F0"
                boxShadow="lg"
                transition="all 0.3s ease"
            >
                <Image 
                    bg="gray.200" 
                    h="250px" 
                    w="350px" 
                    borderTopRadius="15px" 
                    src={allCampaigns[campaignId].imageUrl || "logo.png"}
                    alt={allCampaigns[campaignId].campaignName}
                />
                <Divider />
                <Box padding="4%">
                    <Flex align="center">
                        <Text 
                            fontSize="lg" 
                            color="blackAlpha.800" 
                            fontWeight="semibold"
                            isTruncated
                        >
                            {allCampaigns[campaignId].campaignName}
                        </Text>
                        <Badge 
                            ml="2" 
                            colorScheme="green"
                            borderRadius="full"
                        >
                            {allCampaigns[campaignId].repName}
                        </Badge>
                    </Flex>
                </Box>
            </Box>
        </WrapItem>
    )
}