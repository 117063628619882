import {
  Box,
  Center,
  Flex,
  Input,
  Spinner,
  Stack,
  Text,
  useRadio,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestoreDb } from "../firebase";
import { useQuery } from "@tanstack/react-query";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {withMask} from  'use-mask-input'

interface Plan {
  id: string;
  name: string;
  price: string;
  features: string;
}

interface PlanCardProps {
  plan: Plan;
  value?: any;
  isSelected: boolean;
  onSelect: () => void;
  isDisplayOnly?: boolean;
}

export const PlanCard: FC<PlanCardProps> = ({
  plan,
  value,
  isSelected,
  onSelect,
  isDisplayOnly
}) => {
  const { getInputProps, getRadioProps } = useRadio({ value });
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      position="relative"
      flex={1}
      borderWidth={"2px"}
      borderRadius="md"
      borderColor={isSelected ? "blue.500" : ""}
      padding="4"
      onClick={isDisplayOnly ? () => {} : onSelect}
      marginRight={plan?.name !== "Premium" ? "2" : "0"}
      _hover={!isDisplayOnly ? {
        borderColor: "blue.500",
        borderWidth: 2,
      }: {}}
    >
      <input {...input} hidden />
      <Box {...checkbox}>
        <Text fontWeight="bold">{plan.name}</Text>
        <Text>{plan.price === "Free" ? "Free" : `$${plan.price}/m`}</Text>
        <Text mt={4} fontSize="sm">
          {plan.features}
        </Text>
      </Box>
      {isSelected && (
        <CheckCircleIcon
          position="absolute"
          top={1}
          right={1}
          color="blue.500"
        />
      )}
    </Box>
  );
};

const fetchPlans = async (): Promise<Plan[]> => {
  const querySnapshot = await getDocs(collection(firestoreDb, "pricingPlans"));
  return querySnapshot.docs
    .map((doc) => ({
      id: doc.id,
      ...(doc.data() as any),
    }))
    .sort((a, b) => a.price - b.price);
};

const PricingPlans: FC<any> = ({ state, setState, enableBuyPlan }) => {
  const { data: plans } = useQuery({
    queryKey: ["pricingPlans"],
    queryFn: fetchPlans,
  });

  return (
    <Stack>
      {plans && plans?.length ? (
        <>
        {enableBuyPlan && <Text fontWeight="semibold">Select a plan:</Text>}
          <Flex justify="space-between">
            {plans.map((plan) => {
              const isSelected = state.plan?.id === plan?.id;
              return (
                <PlanCard
                  key={plan.id}
                  value={plan}
                  plan={plan}
                  isDisplayOnly={!enableBuyPlan}
                  isSelected={isSelected}
                  onSelect={() => setState((prev) => ({ ...prev, plan: plan }))}
                />
              );
            })}
          </Flex>
          {state.plan && state.plan?.name !== "Free" && enableBuyPlan && (
            <>
            <Text fontWeight="semibold">Card details:</Text>
              <Flex direction="row" gap={2}>
              <Input
                placeholder="Card Number"
                value={state.creditCard.number}
                ref={withMask('9999 9999 9999 9999', {
                  showMaskOnHover: false,
                  placeholder: ''
                })}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    creditCard: { ...prev.creditCard, number: e.target.value },
                  }))
                }
              />
              <Input
                placeholder="MM/YY"
                value={state.creditCard.expiry}
                ref={withMask('99/99', {
                  showMaskOnHover: false,
                  placeholder: '_',
                })}
                w={180}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    creditCard: { ...prev.creditCard, expiry: e.target.value },
                  }))
                }
              />
              <Input
                placeholder="CVV"
                value={state.creditCard.cvv}
                ref={withMask('9999', {
                  showMaskOnHover: false,
                  placeholder: ''
                })}
                w={120}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    creditCard: { ...prev.creditCard, cvv: e.target.value },
                  }));
                }}
              />
              </Flex>
            </>
          )}
        </>
      ) : (
        <Center w="100%" py={10}>
          <Spinner size="xl" />
        </Center>
      )}
    </Stack>
  );
};

export default PricingPlans;
