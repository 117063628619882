import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PricingPlans from "./pricingPlans";
import { useProfileState } from "../hooks/useProfileState";
import { useBillingModalState } from "../hooks/useBillingModalState";
import { useBuyPlan } from '../hooks/useBuyPlan';

const BillingModal = () => {
  const { data: profile } = useProfileState();
  const modalState = useBillingModalState();
  const [state, setState] = useState({
    plan: profile?.plan,
    creditCard: {
      number: "",
      expiry: "",
      cvv: "",
    },
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, plan: profile?.plan }));
  }, [profile?.plan]);

  const {buyPlan, isPending, validateBeforeBuyPlan} = useBuyPlan({
    onSuccess: () => {
      modalState.setVisible(false);
      setState({plan: profile?.plan, creditCard: {number: '', expiry: '', cvv: ''}});
    }
  });


  const onClose = () => modalState.setVisible(false);

  const handleSubscribe = () => {
    if (isPending) return;

    const isValid = validateBeforeBuyPlan({card: state.creditCard, plan: state.plan});
    if (!isValid) return;

    buyPlan({planId: state.plan.id, card: state.creditCard, userId: profile?.user.uid});
  }

  const isSubscribeDisabled = !state.plan || (state.plan?.creditMinutes !== 0 && (!state.creditCard.number || !state.creditCard.expiry || !state.creditCard.cvv))

  const paymentDate = profile?.lastPaymentDate; // YYYY-MM-DD
  const paymentDay = paymentDate ? new Date(paymentDate).getDate() : null;
  return (
    <Modal
      isOpen={modalState.visible || (profile?.id && !profile?.plan)}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Billing</ModalHeader>
        {profile?.plan && <ModalCloseButton />}
        <ModalBody>
          {profile?.plan ? (
            <Stack mb={4}>
              <Text >
                You are currently on the <strong>{profile.plan.name}</strong>{" "}
                plan.
              </Text>
              {(profile?.plan.creditMinutes !== 0 && paymentDay) && (
                <Text>
                  Payments occur on day <strong>{paymentDay}</strong> of every month
                </Text>
              )}
              <Text><strong>Remaining credit:</strong> {profile?.plan.creditMinutes === 1000000 ? 'Unlimited' : `${profile.remainingPlanMinutes} minutes`}</Text>
            </Stack>
          ) : (
            <Text mb={4}>
              You haven't enabled a plan yet. Please select a plan to continue.
            </Text>
          )}
          <PricingPlans state={state} setState={setState} enableBuyPlan={!profile?.plan} />
        </ModalBody>
        <ModalFooter>
          {!profile?.plan && (
            <Button
              colorScheme="blue"
              isDisabled={isSubscribeDisabled}
              isLoading={isPending}
              onClick={handleSubscribe}
            >
              Subscribe
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BillingModal;
