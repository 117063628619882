import { fetcher } from '../utils/api/fetcher';

export default async function fetchDeleteChat(chatId:string, campaignId:string, assistantId: string, fileId: string) {
    const body = {
        chatId,
        campaignId,
        assistantId: assistantId ? assistantId : null,
        fileId: fileId ? fileId : null
    }
    const response = await fetcher('/delete_chat', {
        method: 'POST',
        body: JSON.stringify(body)
    });

    return response.json();
}
