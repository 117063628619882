import { fetcher } from '../utils/api/fetcher';

export default async function fetchGetUidFromEmail(emails: string[]) {
    const body = {
        emails
    }
    const response = await fetcher('/get_uid_from_email', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
