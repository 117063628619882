import { fetcher } from '../utils/api/fetcher';
import { CampaignDataInterface } from "../utils/interfaces";

export default async function fetchSimulateConversation(campaignData:CampaignDataInterface, customerInfo:any, customerPrompt:string, userId:string, campaignId:string, simKey) {
    const body = {
        call: campaignData,
        customer: customerInfo,
        customerPrompt,
        userId,
        campaignId,
        simKey
    }
    const response = await fetcher('/simulate_conv', {
        method: 'POST',
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error('Failed to create conversation');
    }

    return response.json();
}