import { useMutation } from "@tanstack/react-query";
import { createBilling } from "../helpers/fetchCreateBilling";
import { useToast } from "@chakra-ui/react";

const defaultProps = {};

export const useBuyPlan = ({onSuccess}: {
  onSuccess?: (response: any) => void;
} = defaultProps) => {
  const toast = useToast();

  const { mutateAsync: buyPlan, isPending } = useMutation({
    mutationFn: createBilling,
    onSuccess: (response) => {
      toast({
        title: "Plan Purchased",
        description: "Your plan has been purchased successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if(typeof onSuccess === 'function') onSuccess(response);
    },
    throwOnError: false,
    onError: (error: any) => {
      toast({
        title: "Billing Error",
        description: error.info?.message || "An error occurred while purchasing the plan.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const validateBeforeBuyPlan = ({ card, plan }) => {
    if (!plan?.id){
      toast({
        title: "Plan Error",
        description: "Please select a plan.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (plan?.name !== 'Free' && (!card.number || !card.expiry || !card.cvv)) {
      toast({
        title: "Missing Information",
        description: "Please provide all card details.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
      return false;
    }

    return true;
  };

  const buyPlanWithNormalizer = async (data) => {
    try {
      const newData = {...data};
      const [month, year] = data.card.expiry.split('/');
      const expiryDateFormatted  = `20${year}-${month}`

      newData.card = {
        number: data.card.number.replace(/\s/g, ''),
        expiry: expiryDateFormatted,
        cvv: data.card.cvv
      };
      const response = await buyPlan(newData);
      return response;

    } catch (error) {
      return;
    }
  }

  return {
    buyPlan: buyPlanWithNormalizer,
    isPending,
    validateBeforeBuyPlan
  };
};
