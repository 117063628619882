import { fetcher } from '../utils/api/fetcher';

export default async function fetchGenerateAutomaticResponse(script:any[], campaignId: string) {
    const body = {
        script,
        campaignId
    }
    const response = await fetcher('/generate_automatic_response', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
