import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Input, Textarea, Text, Divider } from "@chakra-ui/react";
import { InstructionsDataInterface } from "../utils/interfaces";

export default function InstructionsComponent({campaignData, setCampaignData, searchInstruction}:InstructionsDataInterface) {
    return (
        <Box>
        {campaignData.repInstructions && campaignData.repInstructions.map((x, idx) => {return {question:x.question, answer:x.answer, idx}}).filter((v) => (v.question.includes(searchInstruction) || v.answer.includes(searchInstruction))).toReversed().map((inst) => {
            return (
                <Flex marginTop="2%" bg="white" boxShadow="sm" padding="2%" border="1px solid #dcdcdc" borderRadius="10px">
                    <Box w="95%">
                        <Flex>
                            <Text marginRight="1%" color="gray.500" fontWeight={600} fontSize="18px" marginTop="auto" marginBottom="auto">Q: </Text>
                            <Input onChange={(e) => {
                                setCampaignData((prev) => {
                                    if (prev) {
                                        return {
                                            ...prev,
                                            repInstructions: [
                                                ...prev.repInstructions!.slice(0, inst.idx),
                                                {...prev.repInstructions![inst.idx], question: e.target.value},
                                                ...prev.repInstructions!.slice(inst.idx + 1)
                                            ]
                                        };
                                    }
                                    return prev;
                                })}} borderRadius="20px" placeholder="Question" value={inst.question} border="1px solid white" color="gray.700" />
                        </Flex>
                        <Divider marginTop="1%" />
                        <Flex>
                            <Text marginRight="1%" color="gray.500" marginTop="auto" marginBottom="auto" fontWeight={600} fontSize="18px">A: </Text>
                            <Textarea
                            color="gray.700"
                            border="1px solid white"
                            marginTop="1%"
                            borderRadius="10px"
                            w="98%"
                            placeholder="Answer"
                            value={inst.answer} 
                            onChange={(e) => {
                                setCampaignData((prev) => {
                                    if (prev) {
                                        return {
                                            ...prev,
                                            repInstructions: [
                                                ...prev.repInstructions!.slice(0, inst.idx),
                                                {...prev.repInstructions![inst.idx], answer: e.target.value},
                                                ...prev.repInstructions!.slice(inst.idx + 1)
                                            ]
                                        };
                                    }
                                    return prev;
                                });
                            
                            }}
                        />
                        </Flex>
                    </Box>
                    <IconButton onClick={() => {
                        setCampaignData((prev) => {
                            if (!prev || !prev.repInstructions) return prev;
                
                            return {
                                ...prev,
                                repInstructions: prev.repInstructions.filter((_, i) => i !== inst.idx)
                            };
                        }
                        )}} w="5%" colorScheme="gray" marginTop="auto" marginBottom="auto" marginLeft='1%' borderRadius="20px" aria-label="Delete field" icon={<DeleteIcon />} size='sm' variant='outline' />
                </Flex>
            )
        })}
        </Box>
    )
}