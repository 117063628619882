import {
  Box,
  Center,
  Flex,
  HStack,
  Spacer,
  Text,
  Divider,
  Badge,
  Select,
} from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { firestoreDb } from "../firebase";
import { CalendarIcon } from "@chakra-ui/icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import { CallDataInterface } from "../utils/interfaces";
import AppointmentRecording from '../components/AppointmentRecording';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const formatDayWithYear = (day: string) => {
  return day?.length === 10 ? day : `${day}-${dayjs().year()}`;
};

const Appointments = ({ campaignId }: { campaignId: string }) => {
  const [filterDate, setFilterDate] = useState<string>("all");
  const [allAppointments, setAllAppointments] = useState<CallDataInterface[]>(
    []
  );

  const filteredAppointments = useMemo(() => {
    const today = dayjs().startOf("day").format("MM-DD-YYYY");
    const yesterday = dayjs().subtract(1, "day").format("MM-DD-YYYY");
    const last7Days = dayjs().subtract(6, "days").format("MM-DD-YYYY");
    const monthToDate = dayjs().startOf("month").format("MM-DD-YYYY");

    return allAppointments.filter((appointment) => {
      const appointmentDay = appointment.evaluation!.appointmentInfo.day;
      const appointmentDate = dayjs(
        formatDayWithYear(appointmentDay),
        "MM-DD-YYYY"
      );
      switch (filterDate) {
        case "today":
          return appointmentDate.isSame(today, "day");
        case "yesterday":
          return appointmentDate.isSame(yesterday, "day");
        case "last7days":
          return (
            appointmentDate.isSameOrAfter(last7Days) &&
            appointmentDate.isSameOrBefore(today)
          );
        case "monthtodate":
          return (
            appointmentDate.isSameOrAfter(monthToDate) &&
            appointmentDate.isSameOrBefore(today)
          );
        default:
          return (
            filterDate === "all" ||
            appointmentDate.isSame(dayjs(filterDate, "MM-DD-YYYY"), "day")
          );
      }
    });
  }, [allAppointments, filterDate]);

  useEffect(() => {
    const retrieveAppointments = async () => {
      const appointmentsRef = collection(
        firestoreDb,
        "campaigns",
        campaignId,
        "calls"
      );
      const appointmentsQuery = query(
        appointmentsRef,
        where("evaluation.appointmentInfo", "!=", null)
      );
      const querySnapshot = await getDocs(appointmentsQuery);
      const allSnapshots: any[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          allSnapshots.push({
            id: doc.id,
            ...doc.data()
          });
        }
      });
      if (allSnapshots.length > 0) {
        allSnapshots.sort((a, b) => {
          const dayA = a.evaluation!.appointmentInfo.day;
          const dayB = b.evaluation!.appointmentInfo.day;
          const dateA = dayjs(formatDayWithYear(dayA), "MM-DD-YYYY").toDate();
          const dateB = dayjs(formatDayWithYear(dayB), "MM-DD-YYYY").toDate();
          return dateA.getTime() - dateB.getTime();
        });
      }
      setAllAppointments(allSnapshots);
    };
    retrieveAppointments();
  }, []);
  return (
    <Box
      padding="5%"
      paddingTop="2%"
      overflowY="auto"
      bg="#fbfafb"
      w="85%" h="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        w="60%"
        h="90vh"
        boxShadow="lg"
        mt='20px'
        border="1px solid #e8e8e8"
        borderRadius="10px"
      >
        <Box padding="10px">
          <Flex marginBottom="1%">
            <Text fontSize="25px" fontWeight={200}>
              Appointments
            </Text>
            <Spacer />
            <Select
              value={filterDate}
              onChange={(e) => {
                setFilterDate(e.target.value);
              }}
              margin="auto"
              w="170px"
              borderRadius="10px"
            >
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last7days">Last 7 days</option>
              <option value="monthtodate">Month to date</option>
              {allAppointments &&
                Array.from(
                  new Set(
                    allAppointments.map((appointment) =>
                      formatDayWithYear(
                        appointment.evaluation!.appointmentInfo.day
                      )
                    )
                  )
                )
                  .filter((day) => day?.length > 0)
                  .map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
            </Select>
          </Flex>
          <Divider />
        </Box>
        <Box height="100%" padding="10px 14px" overflowY="auto">
          {filteredAppointments.length > 0 ? (
            filteredAppointments.map((appointment, index) => {
              return (
                <Box
                  bg="#f4f4f4"
                  key={index}
                  marginBottom="2%"
                  boxShadow="base"
                  borderRadius="20px"
                >
                  <Flex h="100px" borderRadius="20px">
                    <Center
                      borderTopLeftRadius="20px"
                      margin="auto"
                      w="10%"
                      h="100%"
                      bg="#2b6cb0"
                    >
                      <CalendarIcon margin="auto" color="white" />
                    </Center>
                    <Box w="90%" margin="auto" marginLeft="1%">
                      <Text fontSize="20px" fontWeight={600}>
                        {appointment.toName}
                      </Text>
                      <HStack>
                        {[
                          "manufacturer_vehicle",
                          "model_vehicle",
                          "year_vehicle",
                        ].map((key) => {
                          if (
                            appointment.evaluation!.appointmentInfo[key] &&
                            appointment.evaluation!.appointmentInfo[key]
                              .length > 1
                          ) {
                            return (
                              <Badge borderRadius="5px" colorScheme="blue">
                                {appointment.evaluation!.appointmentInfo[key]}
                              </Badge>
                            );
                          }
                        })}
                      </HStack>
                      <Text fontSize="20px">
                        {appointment.evaluation!.appointmentInfo.day}
                      </Text>
                      <Text
                        marginTop="auto"
                        fontSize="15px"
                        fontWeight={400}
                      >{`${appointment.evaluation!.appointmentInfo.time} - ${
                        appointment.timezone
                      }`}</Text>
                    </Box>
                  </Flex>
                  <AppointmentRecording 
                    call={{
                      id: appointment?.id,
                      audioUrl: appointment?.audioUrl,
                    }} 
                  />
                </Box>
              );
            })
          ) : (
            <Center height="100%">
              <Text fontSize="20px" fontWeight={600}>
                No appointments found
              </Text>
            </Center>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Appointments;
