import { fetcher } from '../utils/api/fetcher';

export default async function fetchSendChatMessage(message:string, campaignId:string, chatId:string, assistantId:string, threadId:string, model:string) {
    const body = {
        message,
        campaignId,
        chatId,
        assistantId: assistantId ? assistantId : null,
        threadId: threadId ? threadId : null,
        model
    }
    const response = await fetcher('/analytics_chat', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
