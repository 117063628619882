import { fetcher } from '../utils/api/fetcher';

export default async function fetchCheckEmail(email:string) {
    const body = {
        email
    }
    const response = await fetcher('/check_email', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
