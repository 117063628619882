import React, { useState, useRef } from 'react';
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  useToast,
  Text,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  IconButton,
  Flex,
  Tooltip,
  Checkbox,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Center,
} from '@chakra-ui/react';
import { firestoreDb, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import fetchParseCustomersCsv from '../helpers/fetchParseCustomersCsv';
import { BsUpload } from 'react-icons/bs';
import { AddIcon } from '@chakra-ui/icons';
import { CampaignDataInterface } from '../utils/interfaces';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';


export default function CSVUploader({campaignData, campaignId, allCustomers}: {campaignData:CampaignDataInterface, campaignId:string, allCustomers:any}){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAudience, setSelectedAudience] = useState<string>('');
  const [createAudience, setCreateAudience] = useState<boolean>(false);
  const [newAudience, setNewAudience] = useState<string>('');
  const [deletePreviousCustomers, setDeletePreviousCustomers] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [newCustomerInfo, setNewCustomerInfo] = useState<any>({});
  const [newCustomerOption, setNewCustomerOption] = useState<number>(0);
  const inputRef = useRef();
  const toast = useToast();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setLoading(true);
    uploadFileToFirebase(file).then(() => {
      onOpen();
    });
  };


  async function uploadFileToFirebase(file:any){
    const randomString = Math.random().toString(36).substring(2, 7);
    const storageRef = ref(storage, `customers/${randomString}.csv`)
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setUrl(downloadURL);
        setLoading(false);
      });
    }).catch((error) => {
      console.error("Upload failed:", error);
    });
  };


  function manuallyAddCustomer(){
    if (newCustomerInfo.phone_number.length !== 12){
      toast({
        title: 'Invalid phone number',
        description: 'Please enter a valid phone number.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }
    if (!newCustomerInfo.name || !newCustomerInfo.product){
      toast({
        title: 'Missing information',
        description: 'Please fill in the name, phone number and product of interest of your customer.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }
    const customersRef = collection(firestoreDb, 'campaigns', campaignId, 'customers')
    // Create new document with newCustomerInfo

    addDoc(customersRef, newCustomerInfo).then(() => {
      toast({
        title: 'Customer added',
        description: 'Your customer has been added to the list.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      onClose();
    }).catch((error) => {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    })
  }

  function saveCustomers(){
    setLoading(true);
    toast({
      title: 'Processing contacts',
      description: 'Your contacts are being processed. You will be able to access them in a few minutes.',
      status: 'info',
      duration: 5000,
      isClosable: true
    
    });
    fetchParseCustomersCsv(url, campaignId, selectedAudience, deletePreviousCustomers).then((res) => {
      toast({
        title: 'Processing finished',
        description: res.message,
        status: res.status,
        duration: 5000,
        isClosable: true
      });
      setLoading(false);
      onClose();
    }
  ).catch((error) => {
    toast({
      title: 'Error',
      description: error.message,
      status: 'error',
      duration: 5000,
      isClosable: true
    });
    setLoading(false);
    })
  }

  function updateAudience(){
    const audiencesRef = doc(firestoreDb, 'campaigns', campaignId)
    updateDoc(audiencesRef, {
      audiences: campaignData.audiences ? [...campaignData.audiences, newAudience] : [newAudience]
    }).then(() => {setCreateAudience(false); setNewAudience('');})
  }

  return (
    <Box marginTop="auto" marginBottom="auto" >
      <Button onClick={() => {onOpen()}} leftIcon={<AddIcon />} size="sm" variant="outline" colorScheme="blue" isLoading={loading}>Add Customers</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody h="60vh" padding="5%" overflowX="auto" overflowY="auto">
            <Accordion index={newCustomerOption} marginTop="5%" onChange={(e) => {setNewCustomerOption(e)}}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize="22px" fontWeight={200}>Manually add customer</Text>
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Input value={newCustomerInfo.name} onChange={(e) => {setNewCustomerInfo((prev) => ({...prev, name: e.target.value}))}} placeholder="Customer name"  marginBottom="1.5%"/>
                  <PhoneInput
                        forceDialCode={true}
                        placeholder="Customer phone number"
                        defaultCountry="us"
                        value={newCustomerInfo.phone_number}
                        onChange={(phone) => setNewCustomerInfo((prev) => ({...prev, phone_number: phone}))}
                        countrySelectorStyleProps={{buttonStyle: {background: 'transparent', height: '40px'}}}
                        inputStyle={{width: '100%', fontSize:"15px", background:"transparent", height: '40px'}}
                        countries={defaultCountries.filter((country) => {
                            const { iso2 } = parseCountry(country);
                            return ['us'].includes(iso2);
                          })}
                    />
                  <Input value={newCustomerInfo.product} onChange={(e) => {setNewCustomerInfo((prev) => ({...prev, product: e.target.value}))}} marginTop="1.5%" placeholder="Product of interest" />
                  <Input value={newCustomerInfo.email} onChange={(e) => {setNewCustomerInfo((prev) => ({...prev, email: e.target.value}))}} marginTop="1.5%" placeholder="Customer email (optional)" />
                  <Input value={newCustomerInfo.address} onChange={(e) => {setNewCustomerInfo((prev) => ({...prev, address: e.target.value}))}} marginTop="1.5%" placeholder="Customer address (optional)" />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize="22px" fontWeight={200}>Or upload your customers from a CSV</Text>
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Center>
                    <Button leftIcon={<BsUpload />} onClick={() => inputRef.current.click()}>Load CSV with customers</Button>
                  </Center>
                  <input
                    type="file"
                    ref={inputRef}
                    hidden
                    accept=".csv"
                    onChange={handleFileUpload}
                  />
                  <Text marginTop="2%" color="gray.600" fontSize="13px" marginBottom="4%">Make sure your document contains information about the name, phone number and product of interest of your lead customers. You can also add other informations such as the email, address, etc.</Text>
                  <Flex w="95%">
                    {!createAudience ? (
                    <Box>
                      <Text fontWeight={600}>Pick an audience</Text>
                      <Select value={selectedAudience} onChange={(e) => {setSelectedAudience(e.target.value)}} placeholder="Assign an audience to this list of customers">
                        {campaignData.audiences && campaignData.audiences.map((audience) => (
                          <option value={audience}>{audience}</option>
                        ))}
                    </Select>
                    </Box>)
                    : (
                    <Box w="100%">
                      <Text fontWeight={600}>Create a new audience</Text>
                      <Flex>
                        <Input value={newAudience} onChange={(e) => setNewAudience(e.target.value)} placeholder="Audience name" />
                        <Button onClick={() => {updateAudience()}} marginLeft="3%" colorScheme="blue" variant="outline">Create</Button>
                      </Flex>
                    </Box>
                    )}
                    {!createAudience && (
                    <Tooltip label="Create a new audience" aria-label="A tooltip">
                      <IconButton onClick={() => {setCreateAudience(true)}} marginTop="auto" aria-label="Add audience" icon={<AddIcon />} variant="outline" borderRadius="20px" marginLeft="3%" />
                    </Tooltip>)}
                  </Flex>
                  <Checkbox isChecked={deletePreviousCustomers} onChange={(e) => {setDeletePreviousCustomers(e.target.checked)}} fontWeight={600} marginTop="7%" colorScheme="blue">
                    Delete previous customers
                  </Checkbox>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={loading} onClick={() => {newCustomerOption === 1 ? saveCustomers() : manuallyAddCustomer()}} colorScheme="blue" mr={3}>
              {newCustomerOption === 1 ? "Save List" : "Add Customer"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};