import { Box, Button, Center, useToast, Flex, HStack, IconButton, Textarea, InputGroup, InputRightElement, Spacer, Tab, TabList, Tabs, Text, Image, Avatar, Tooltip, TabPanels, TabPanel, Divider, Badge, Select } from "@chakra-ui/react";
import { IoMdPaperPlane } from "react-icons/io";
import fetchSendChatMessage from "../helpers/fetchSendChatMessage";
import { useState, useEffect, KeyboardEvent } from "react";
import { firestoreDb } from "../firebase";
import { BsLightbulb, BsLightning, BsPerson } from "react-icons/bs";
import { CloseIcon } from "@chakra-ui/icons";
import fetchDeleteChat from "../helpers/fetchDeleteChat";
import { addDoc, collection, onSnapshot, query } from "firebase/firestore";
import { CampaignDataInterface } from "../utils/interfaces";
import ResizeTextarea from "react-textarea-autosize";
import StatsCards from '../components/StatsCards';


type AnalyticsPageProps = {
    campaignData?: CampaignDataInterface;
    campaignId: string;
};



export default function AnalyticsPage({ campaignData, campaignId }: AnalyticsPageProps) {
    const [availableChats, setAvailableChats] = useState<{[key:string]: {assistantId:string, createdAt:number, fileId:string, model:string, threadId:string}}>();
    const [currentChat, setCurrentChat] = useState<string>('');
    const [model, setModel] = useState<string>("gpt-4-1106-preview");
    const [loading, setLoading] = useState<boolean>(false);
    const [boxText, setBoxText] = useState<string>('');
    const [tabIndex, setTabIndex] = useState<number>(-1);

    const suggestedQuestions: string[] = [
        "How many calls were completed in the last week?",
        "Generate a chart displaying completed calls and achieved objectives."
    ];
    const toast = useToast();

    useEffect(() => {
        const assistantsChatsRef = collection(firestoreDb, 'campaigns', campaignId, 'assistantsChats');
        const q = query(assistantsChatsRef);
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedChats = {};
            snapshot.forEach((doc) => {
                fetchedChats[doc.id] = doc.data();
            });
            setAvailableChats(fetchedChats);
        });
        return () => unsubscribe();
    }, [])


    useEffect(() => {
        if (currentChat.length > 0) {
            const messagesRef = collection(firestoreDb, 'campaigns', campaignId, 'assistantsChats', currentChat, 'messages');
            const q = query(messagesRef);
            const unsubscribe = onSnapshot(q, (snapshot) => {
                let chats = {...availableChats};
                chats[currentChat].messages = {}
                snapshot.forEach((doc) => {
                    chats[currentChat].messages[doc.id] = doc.data();
                });
                //Order by createdAt
                chats[currentChat].messages = Object.fromEntries(Object.entries(chats[currentChat].messages).sort(([,a],[,b]) => b.createdAt - a.createdAt));
                setAvailableChats(chats);
            });
            return () => unsubscribe();
        }
    }, [currentChat])

    useEffect(() => {
        if (tabIndex === -1 && availableChats) {
            setTabIndex(Object.keys(availableChats).length)
        }
    }, [availableChats])

    async function sendMessage(question: string = '') {
        let chatId;
        let assistantId;
        let threadId;
        if(currentChat.length === 0){
            const docRef = await addDoc(collection(firestoreDb, 'campaigns', campaignId, 'assistantsChats'), {
                createdAt: Date.now() / 1000,
                model
            });
            setCurrentChat(docRef.id);
            chatId = docRef.id;
            assistantId = null;
            threadId = null;
        } else {
            chatId = currentChat;
            assistantId = availableChats[currentChat].assistantId;
            threadId = availableChats[currentChat].threadId;
        }
        setLoading(true);
        let messageToSend:string
        if (question.length === 0) {
            messageToSend = boxText
        } else {
            messageToSend = question
            setTabIndex(0)
        }
        setBoxText('');
        await fetchSendChatMessage(messageToSend, campaignId, chatId, assistantId, threadId, model).then((res) => {
            if (res.status === 'error'){
                toast({
                    title: 'Error',
                    description: res.message,
                    status: 'error',
                    duration: 8000,
                    isClosable: true
                })
                setTabIndex(Object.keys(availableChats).length + 1)
                setCurrentChat('')
            }
        })
        setLoading(false)
    }

    function deleteChat(chatId: string) {
        setTabIndex(-1)
        setCurrentChat('')
        const assistantId = availableChats[chatId].assistantId;
        const fileId = availableChats[chatId].fileId;
        fetchDeleteChat(chatId, campaignId, assistantId, fileId).then(() => {
            toast({
                title: 'Chat deleted',
                description: 'Chat was deleted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            })
        })
    }

    const onPressEnterInsideTextarea = (e: KeyboardEvent<HTMLTextAreaElement>) => { 
        if(e.key === 'Enter') {
            e.preventDefault();
            sendMessage(); 
        } 
    }

    return (
        <Box bg="#fbfafb" w="85%" h="100vh">
                <Tabs size="lg" variant="solid-rounded" marginTop="2%" colorScheme="blue" h="full">
                    <Center>
                        <TabList>
                            <Tooltip borderRadius="10px" label="Important statistics">
                                <Tab>Stats</Tab>
                            </Tooltip>
                            <Tooltip borderRadius="10px" label="Want more personalized information? Ask our chatbot everything about your campaign!">
                                <Tab>Chat</Tab>
                            </Tooltip>
                        </TabList>
                    </Center>
                    <TabPanels padding="1%" h="full">
                        <TabPanel h="full">
                            <StatsCards campaignId={campaignId} />
                        </TabPanel>
                        <TabPanel>
                            <Center>
                                <Box border="1px solid #dadada" w="90%" bg="white" h="80vh" borderRadius="20px" boxShadow="2xl">
                                    <Flex direction="column" h="98%">
                                        <Tabs index={tabIndex} onChange={(idx) => {setTabIndex(idx)}} isFitted colorScheme="blue">
                                            <TabList>
                                                {availableChats && Object.keys(availableChats).map((chatId, index) => (
                                                    <Tab onClick={() => setCurrentChat(chatId)} key={index}>
                                                        #{index + 1}
                                                        <Tooltip label="Delete chat">
                                                            <IconButton size="sm" variant="ghost" borderRadius="20px" aria-label="Delete Chat" onClick={(e) => {
                                                                e.stopPropagation(); // Prevent Tab click event
                                                                deleteChat(chatId);
                                                            }} icon={<CloseIcon fontSize="9px" />} />
                                                        </Tooltip>
                                                    </Tab>
                                                )).reverse()}
                                                <Tab onClick={() => setCurrentChat('')} fontWeight={600}>+ New</Tab>
                                            </TabList>
                                        </Tabs>
                                        {currentChat.length === 0 && (
                                            <Center marginTop="3%">
                                                <Box>
                                                    <HStack spacing={4} marginTop="2%">
                                                        <Button colorScheme={model === "standard" ? "blue" : "gray"} leftIcon={<BsLightning />} variant="outline" onClick={() => {setModel("standard")}}>Faster</Button>
                                                        <Button colorScheme={model === "turbo" ? "blue" : "gray"} leftIcon={<BsLightbulb />} variant="outline" onClick={() => {setModel('turbo')}}>Smarter</Button>
                                                    </HStack>
                                                </Box>
                                            </Center>
                                        )}
                                        {availableChats && availableChats[currentChat] && availableChats[currentChat].messages && (
                                        <Box h="100%" overflowY="auto" padding="2%">
                                            {Object.values(availableChats[currentChat].messages).reverse().map((message: any, index: number) => (
                                                <Box padding="1%" borderRadius="10px"  key={index}>
                                                    {message.role === 'user' ? (
                                                    <Box w="60%"  bg="gray.100" borderRadius="30px" padding="1.5%">
                                                        <Flex>
                                                            <Avatar size="sm" bg='blue.500' icon={<BsPerson fontSize="150%" />} />
                                                            <Text marginLeft="1%" marginTop="auto" fontSize="17px" marginBottom="auto" fontWeight={600}>You</Text>
                                                        </Flex>
                                                        <Text marginTop="0.5%" fontSize="sm">{message.content}</Text>
                                                    </Box>)
                                                    :
                                                    (<Flex justifyContent="flex-end">
                                                        <Box bg="gray.100" borderRadius="30px" padding="1.5%" w="60%">
                                                            <Flex justifyContent="flex-end">
                                                                <Text marginRight="1%" marginTop="auto" fontSize="17px" fontWeight={600}>Assistant</Text>
                                                                <Avatar size="sm" src="logo.ico" />
                                                            </Flex>
                                                        {message.type === 'image' ? <Image cursor="pointer" onClick={() => window.open(message.content, '_blank')} marginTop="0.5%" src={message.content} /> : <Text marginTop="0.5%" fontSize="sm">{message.content}</Text>}
                                                        </Box>
                                                    </Flex>)}
                                                </Box>
                                            ))}
                                        </Box>)}
                                        <Spacer />
                                        {currentChat.length === 0 && (
                                        <Center>
                                            <HStack marginBottom="2%" marginTop="1%" spacing={4} alignItems="flex-start">
                                                {suggestedQuestions.map((question, index) => (
                                                    <Button size="sm" key={index} variant="outline" borderRadius="20px" onClick={() => {sendMessage(question)}}>
                                                        {question}
                                                    </Button>
                                                ))}
                                            </HStack>
                                        </Center>)}
                                        <Center>
                                            <InputGroup size="md" w="90%">
                                                <InputRightElement width='3rem'>
                                                    <IconButton onClick={() => {sendMessage()}} variant="link" aria-label="Send message" isLoading={loading} icon={<IoMdPaperPlane color='gray.300' />} />
                                                </InputRightElement>
                                                <Textarea
                                                    value={boxText}
                                                    onChange={(e) => {setBoxText(e.target.value)}}
                                                    placeholder="Ask anything about your campaign"
                                                    fontSize="15px"
                                                    borderRadius="20px"
                                                    minH="unset"
                                                    w="100%"
                                                    resize="none"
                                                    paddingRight="3rem"
                                                    minRows={1}
                                                    maxRows={10}
                                                    as={ResizeTextarea}
                                                    onKeyDown={onPressEnterInsideTextarea}
                                                />
                                            </InputGroup>
                                        </Center>
                                        {currentChat.length > 0 && availableChats && availableChats[currentChat] &&  <Text color="gray.400" fontSize="12.5px" marginTop="1%" fontWeight={600} textAlign="center">{`Knowledge cutoff: ${new Date(availableChats[currentChat].createdAt*1000).toLocaleDateString()}. Open a new tab to get more recent data`}</Text>}
                                    </Flex>
                                </Box>
                            </Center>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
        </Box>
    )
}