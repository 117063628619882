import { AddIcon, SearchIcon, DeleteIcon, RepeatIcon, ArrowBackIcon, PhoneIcon } from "@chakra-ui/icons";
import { Flex, Spacer, Button, Center, IconButton, Divider, InputGroup, InputLeftElement, Input, useToast, Box, Text, Modal, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalContent, ModalFooter, Spinner, Tooltip, Avatar, InputRightElement,} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ConfigDataInterface } from "../utils/interfaces";
import InstructionsComponent from "../components/instructions";
import { auth, firestoreDb } from "../firebase";
import fetchCustomerPrompt from "../helpers/fetchCustomerPrompt";
import fetchSimulateConversation from "../helpers/fetchSimulateConversation";
import { GrConfigure, GrPause, GrPlay, GrSend } from "react-icons/gr";
import fetchGenerateAudio from "../helpers/fetchGenerateAudio";
import InventoryComponents from "../components/inventory";
import DummyCall from "../components/dummyCall";
import { BsHandThumbsUp, BsRobot } from "react-icons/bs";
import { addDoc, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { isEqual } from 'lodash';
import ConfigPrompt from "../components/configPrompt";


export default function PlaygroundPage({campaignData, setCampaignData, prevData, setPrevData, campaignId}:ConfigDataInterface){
    const [showSave, setShowSave] = useState<boolean>(false);
    const [searchInstruction, setSearchInstruction] = useState<string>('');
    const [openConversationSettings, setOpenConversationSettings] = useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = useState<any>({name: "George", product: "", details: ''});
    const [loading, setLoading] = useState<boolean>(false);
    const [syntheticConv, setSyntheticConv] = useState<{like: boolean, dialogue: any[]}>([]);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>(new Audio());
    const [runningAudio, setRunningAudio] = useState<number>(-1);
    const [reviewSuggestion, setReviewSuggestion] = useState<string>('');
    const [searchReview, setSearchReview] = useState<string>('');
    const [reviewMessage, setReviewMessage] = useState<{content:string, suggestion:string}>({content: '', suggestion: ''});
    const [openDummyCall, setOpenDummyCall] = useState<boolean>(false);
    const [customerPrompt, setCustomerPrompt] = useState<string>('');
    const [openConfigPrompt, setOpenConfigPrompt] = useState<boolean>(false);
    const [simKey, setSimKey] = useState<string>('');
    const toast = useToast();

    useEffect(() => {   
        setCampaignData(prevData);
    }, [])

    useEffect(() => {
        if (!isEqual(campaignData, prevData)) {
            setShowSave(true);
        } else {
            setShowSave(false);
        }
    }, [campaignData, prevData])

    useEffect(() => {
        if (simKey){
            const simulationsRef = doc(firestoreDb, 'campaigns', campaignId, 'simulations', simKey);
            const unsubscribe = onSnapshot(simulationsRef, (doc) => {
                if (doc.exists()){
                    setSyntheticConv(doc.data());
                }
            })
            return () => unsubscribe();
        }
    }, [simKey])

    function saveChanges(){
        const projectRef = doc(firestoreDb, 'campaigns', campaignId);
        updateDoc(projectRef, campaignData).then(() => {
            toast({
                title: "Changes saved.",
                description: "Your changes have been saved.",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            setPrevData(campaignData);
        }
        )
        }
    
    async function generateCustomer(){
        if(customerInfo.product.length === 0){
            return toast({
                title: "Invalid product",
                description: "Please enter a valid product.",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
        if(customerInfo.name.length === 0){
            return toast({
                title: "Invalid name",
                description: "Please enter a valid name.",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
        setLoading(true);
        const response = await fetchCustomerPrompt(customerInfo)
        return response.prompt;

    }

    async function generateConversation(newPrompt:boolean){
        setLoading(true)
        let prompt:string;
        if (newPrompt){
            prompt = await generateCustomer();
            setCustomerPrompt(prompt)
        } else {
            prompt = customerPrompt;
        }
        const simulationsRef = collection(firestoreDb, 'campaigns', campaignId, 'simulations');
        const doc = await addDoc(simulationsRef, {})
        setSimKey(doc.id);
        setOpenConversationSettings(false);
        fetchSimulateConversation(campaignData, customerInfo, prompt, auth.currentUser!.uid, campaignId, doc.id).then((res) => {
            setLoading(false)
        })
    }

    function playAudio(text:string, index:number){
        if (runningAudio !== -1){
            // Pause any current audio being played
            currentAudio.pause();
            setCurrentAudio(new Audio());
            setRunningAudio(-1);
        } else {
            setRunningAudio(index);
            fetchGenerateAudio(text, campaignData.repVoice).then(async (res) => {
                res.blob().then((blob) => {
                    const mulawBlob = new Blob([blob], { type: 'audio/mpeg' });
                    const url = URL.createObjectURL(mulawBlob);
                    const audio = new Audio(url);
                    audio.play();
                    setCurrentAudio(audio)
                    audio.onended = () => {
                        setRunningAudio(-1);
                    }
                }
                )
            })
            }
        }

    function saveReview(){
        let reviews = campaignData.reviews ? campaignData.reviews : [];
        reviews.push({content: reviewMessage.content, suggestion: reviewSuggestion});
        const suggestionsRef = doc(firestoreDb, 'campaigns', campaignId);
        updateDoc(suggestionsRef, {reviews: reviews}).then(() => {
            toast({
                title: "Review saved.",
                description: "Your review has been saved.",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            setReviewMessage((prevData) => ({...prevData, suggestion: reviewSuggestion}));
            setReviewSuggestion('')
        })
    }

    function startReview(content:string){
        const reviews = campaignData.reviews ? campaignData.reviews : [];
        if (reviews.map((x) => x.content).includes(content)){
            const review = reviews.filter((x) => x.content === content)[0];
            setReviewMessage(review);
        } else {
            setReviewMessage({content: content, suggestion: ''});
        }
    }


    function deleteReview(){
        let reviews = campaignData.reviews ? campaignData.reviews : [];
        if (reviews.map((x) => x.content).includes(reviewMessage.content)){
            reviews = reviews.filter((x) => x.content !== reviewMessage.content);
        }
        const suggestionsRef = doc(firestoreDb, 'campaigns', campaignId);
        updateDoc(suggestionsRef, {reviews: reviews}).then(() => {
            toast({
                title: "Review deleted.",
                description: "Your review has been deleted.",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            setReviewMessage((prev) => ({...prev, suggestion: ''}));
            setPrevData((prev) => ({...prev, reviews: reviews}));
        })
    }

    function likeConversation() {
        if (syntheticConv.dialogue.length === 0) return;
        const simulationsRef = doc(firestoreDb, 'campaigns', campaignId, 'simulations', simKey);
        updateDoc(simulationsRef, { like: !syntheticConv.like });
    }

    return (
        <Box padding="5%" paddingTop="2%" overflowY="auto" bg="#fbfafb" w="85%" h="100vh">
            <Box>
                <Flex>
                    <Text fontSize={35} fontWeight={100}>Playground</Text>
                    <Spacer />
                    <Tooltip label="Prompt configurations">
                        <IconButton onClick={() => {setOpenConfigPrompt(true);}} marginRight="1%" aria-label="Check prompt" icon={<BsRobot />} variant="outline" />
                    </Tooltip>
                    <Button onClick={() => {showSave? toast({title: "Save changes before doing a call", description: "There are unsaved changes in your campaign.", status:"error", duration: 5000}) : setOpenDummyCall(true)}} leftIcon={<PhoneIcon />} variant="outline" colorScheme="blue" >Test call</Button>
                    {//showSave && <Button marginLeft="2%" onClick={() => {saveChanges();}} colorScheme="blue">Save changes</Button>
                    }
                </Flex>
                <Flex>
                    <Box  h="80vh" w="80%" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                        <Flex h="8%" padding="1.5%" marginTop="auto" marginBottom="auto" >
                            <Text fontSize="25px" fontWeight={200}>Simulation</Text>
                            <Spacer />
                            {syntheticConv.dialogue && syntheticConv.dialogue.length > 0 && (
                                <Flex>
                                {loading ?
                                    <Spinner marginTop="auto" h="18px" w="18px" />
                                    :
                                    <Flex>
                                        <Tooltip label="Use this conversation as training data">
                                            <IconButton onClick={() => {likeConversation()}} marginTop="auto" marginBottom="auto" aria-label="Like" icon={<BsHandThumbsUp />} variant="ghost" color={syntheticConv.like ? "green" : "black"} />
                                        </Tooltip>
                                        <Tooltip label="Generate new conversation">
                                            <IconButton marginTop="auto" marginBottom="auto" onClick={() => {generateConversation(false)}} aria-label="Refresh" icon={<RepeatIcon />} variant="ghost" />
                                        </Tooltip>
                                    </Flex>}
                                <Tooltip label="Change settings">
                                    <IconButton onClick={() => {setOpenConversationSettings(true)}} aria-label="Configs" icon={<GrConfigure />} variant="ghost" />
                                </Tooltip>
                                </Flex>
                            )}
                        </Flex>
                        <Divider />
                        <Center h="92%" >
                            {syntheticConv.dialogue && syntheticConv.dialogue.length > 0 ? (
                                <Box overflowY="auto" h="100%" padding="2%">
                                    {syntheticConv.dialogue.map((message, index) => {
                                        if (message.role !== 'system') {
                                            return (
                                                <Flex marginTop="2%" key={index} justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}>
                                                    {message.role === 'assistant' && <Avatar name={message.role === 'user' ? customerInfo.name : campaignData.repName} size="sm" marginRight={message.role === 'user' ? '0' : '2'} marginLeft={message.role === 'user' ? '2' : '0'} />}
                                                    <Tooltip label={message.role === 'assistant' ? "Click to review message" : ''}>
                                                        <Box onClick={() => {message.role === 'assistant' && startReview(message.content);}} cursor={message.role === 'assistant' ? "pointer" : ''} bg={message.role === 'assistant' ? "#f1f8ff" : "white"} boxShadow="lg" border={message.role === 'assistant' ? "2px solid #c0dbf6" : reviewMessage.content === message.content ? "2px solid #56a7fe" : "1px solid #e8e8e8"} borderRadius="10px" padding="2%" maxWidth="70%">
                                                            <Text fontSize={13} fontWeight={600}>{message.role === 'user' ? customerInfo.name : campaignData.repName}</Text>
                                                            <Text color="gray.600" fontSize={12}>{message.content}</Text>
                                                        </Box>
                                                    </Tooltip>
                                                    {message.role === 'assistant' && <IconButton onClick={() => {playAudio(message.content, index)}} size="sm" marginTop="auto" marginBottom="auto" marginLeft="1%" aria-label="Play" icon={index === runningAudio ? <GrPause /> : <GrPlay />} borderRadius="20px" variant="outline" />}
                                                    {message.role === 'user' && <Avatar name={message.role === 'user' ? customerInfo.name : 'Rep'} size="sm" marginRight={message.role === 'user' ? '0' : '2'} marginLeft={message.role === 'user' ? '2' : '0'} />}
                                                </Flex>
                                            )}
                                            return
                                    })}
                                    
                                </Box>
                            ) : (
                                <Button variant="outline" onClick={() => {setOpenConversationSettings(true);}} colorScheme="blackAlpha">Generate a conversation</Button>
                            )}
                        </Center>
                    </Box>
                    <Box  h="80vh" marginLeft="5%" w="60%" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                        <Flex h="6.5vh" padding="1.5%" marginTop="auto" marginBottom="auto" >
                            {reviewMessage.content !== '' && (
                                <IconButton aria-label="Back" icon={<ArrowBackIcon />} variant="ghost" onClick={() => {setReviewMessage({content: '', suggestion: ''})}} />
                            )}
                            <Text fontSize="25px" fontWeight={200}>Reviews</Text>
                            <Spacer />
                        </Flex>
                        <Divider />
                        {reviewMessage.content !== '' ? (
                            <Flex h="92%" flexDirection="column" justifyContent="space-between">
                                <Box flex="1" padding="2%" overflowY="auto">
                                <Flex marginTop="2%" justifyContent="flex-start">
                                    <Avatar name={campaignData.repName} size="sm" marginRight="2" />
                                    <Box bg="#f1f8ff" boxShadow="lg" border="2px solid #c0dbf6" borderRadius="10px" padding="2%" maxWidth="85%">
                                        <Text fontSize={13} fontWeight={600}>{campaignData.repName}</Text>
                                        <Text color="gray.600" fontSize={12}>{reviewMessage.content}</Text>
                                    </Box>
                                </Flex>
                                    {reviewMessage.suggestion !== '' && (
                                    <Box marginTop="10%">
                                        <Divider />
                                        <Text marginTop="2%" fontSize="20px" fontWeight={200}>Suggestion 👇</Text>
                                        <Text w="auto" marginTop="2%" bg="gray.200" fontSize="14px" borderRadius="10px" padding="2%" colorScheme="blue">{reviewMessage.suggestion} <IconButton colorScheme="red" onClick={() => {deleteReview()}} marginTop="-1%" icon={<DeleteIcon />} variant="ghost" aria-label="Delete" size="xs" /></Text>
                                    </Box>)}
                                </Box>
                                {reviewMessage.suggestion === '' && (
                                <Box>
                                    <Text textAlign="center" fontSize={13} fontWeight={600}>What can be improved?</Text>
                                    <Text textAlign="center" color="gray.600" fontSize={12}>Ex: "make it more concise", "make it happier", etc.</Text>
                                    <InputGroup size='md'>
                                        <Input value={reviewSuggestion} onChange={(e) => {setReviewSuggestion(e.target.value)}} border="1px solid gray" placeholder="Suggestion" />
                                        <InputRightElement width='3rem' >
                                            <IconButton onClick={() => {saveReview();}} aria-label="Send" variant="ghost" h='1.75rem' size='sm' icon={<GrSend />} />
                                        </InputRightElement>
                                    </InputGroup>
                                </Box>)}
                            </Flex>)
                            : (
                                <Box>
                                {campaignData.reviews && campaignData.reviews.length > 0 ? (
                                    <Box h="73vh" overflow="auto" overflowY="auto" padding="2%">
                                        <Center marginTop="4%" marginBottom="4%">
                                            <InputGroup size="sm" w="80%">
                                                <InputLeftElement pointerEvents='none'>
                                                    <SearchIcon color='gray.300' />
                                                </InputLeftElement>
                                                <Input
                                                onChange={(e) => {
                                                setSearchReview(e.target.value)
                                                }}
                                                value={searchReview}
                                                borderRadius="20px"
                                                placeholder='Search'
                                                />
                                            </InputGroup>
                                        </Center>
                                        {campaignData.reviews.filter((r) => r.content.includes(searchReview)).map((review, index) => {
                                            return (
                                                <Flex marginTop="5%" justifyContent="flex-start" key={index}>
                                                    <Avatar name={campaignData.repName} size="sm" marginRight="2" />
                                                    <Box onClick={() => {startReview(review.content);}} cursor="pointer" bg="#ecfff4" boxShadow="lg" border="2px solid #b3ccb2" borderRadius="10px" padding="2%" maxWidth="85%">
                                                        <Text fontSize={13} fontWeight={600}>{campaignData.repName}</Text>
                                                        <Text color="gray.600" fontSize={12}>{review.content}</Text>
                                                    </Box>
                                                </Flex>
                                            )
                                        })}
                                        </Box>)
                                        :
                                        (<Center h="73vh">
                                            <Text w="85%" fontWeight={500} textAlign="center" color="gray" fontSize="14px">You may review the assistant's responses by clicking on its answers after generating a simulation</Text>
                                        </Center>)}
                                </Box>)}
                    </Box>
                </Flex>
                <Flex marginTop="2vh">
                <Box padding="1%" w="47.5%" h="70vh" overflowY="auto" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                    <Flex>
                        <Text fontSize="25px" fontWeight={200}>Instructions</Text>
                        <Spacer />
                        {showSave && (
                        <Button
                            marginLeft="2%"
                            onClick={() => {saveChanges();}}
                            variant="outline"
                            marginTop="auto"
                            marginBottom="auto"
                            marginRight="2%"
                            borderRadius="20px"
                            size="sm"
                            colorScheme="blue"
                        >Save changes</Button>)}
                        <IconButton
                            marginTop="auto"
                            marginBottom="auto"
                            aria-label="Add inventory"
                            icon={<AddIcon />}
                            variant="outline"
                            borderRadius="20px"
                            onClick={() => {setCampaignData((prev) => {
                                if (prev) {
                                    return {
                                        ...prev,
                                        repInstructions: prev.repInstructions ? [...prev.repInstructions, {question: '', answer: ''}] : [{question: '', answer: ''}]
                                    };
                                }
                                return prev;
                            })
                            }}
                            size="sm"
                            />
                    </Flex>
                    <Divider />
                    <Center marginTop="2%">
                        <InputGroup size="sm" w="80%">
                            <InputLeftElement pointerEvents='none'>
                                <SearchIcon color='gray.300' />
                            </InputLeftElement>
                            <Input
                            onChange={(e) => {
                            setSearchInstruction(e.target.value)
                            }}
                            value={searchInstruction}
                            borderRadius="20px"
                            placeholder='Search'
                            />
                        </InputGroup>
                    </Center>
                    <InstructionsComponent campaignData={campaignData} setCampaignData={setCampaignData} searchInstruction={searchInstruction} />
                </Box>
                {/*
                <Box overflowY="auto" padding="1%" w="47.5%" marginLeft="5%" h="70vh" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                    <Flex>
                        <Text fontSize="25px" fontWeight={200}>Inventory</Text>
                        <Spacer />
                        <IconButton
                            marginTop="auto"
                            marginBottom="auto"
                            aria-label="Add inventory"
                            icon={<AddIcon />}
                            variant="outline"
                            borderRadius="20px"
                            onClick={() => {setCampaignData((prev) => {
                                if (prev) {
                                    return {
                                        ...prev,
                                        repProducts: prev.repProducts ? [...prev.repProducts, ''] : ['']
                                    };
                                }
                                return prev;
                            })
                            }}
                            size="sm"
                            />
                    </Flex>
                    <Divider />
                    <Center marginTop="2%">
                        <InputGroup size="sm" w="80%">
                            <InputLeftElement pointerEvents='none'>
                                <SearchIcon color='gray.300' />
                            </InputLeftElement>
                            <Input
                            value={searchProduct}
                            onChange={(e) => {
                            setSearchProduct(e.target.value)
                            }}
                            borderRadius="20px"
                            placeholder='Search'
                            />
                        </InputGroup>
                    </Center>
                    <Box overflowY="auto">
                        <InventoryComponents campaignData={campaignData} setCampaignData={setCampaignData} searchProduct={searchProduct} />
                    </Box>
                </Box>
                */}
            </Flex>
            </Box>
            <Modal isOpen={openConversationSettings} onClose={() => {setOpenConversationSettings(false);}} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Conversation Settings</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize={13} fontWeight={600}>Enter a name for the customer</Text>
                        <Input onChange={(e) => {setCustomerInfo((prev:any) => ({...prev, name: e.target.value}))}} placeholder="Customer name" value={customerInfo.name} />
                        <Text marginTop="5%" fontSize={13} fontWeight={600}>Default product/service</Text>
                        <Input placeholder="Product (lead)" onChange={(e) => {setCustomerInfo((prev:any) => ({...prev, product: e.target.value}))}} value={customerInfo.product} />
                        <Text marginTop="5%" fontSize={13} fontWeight={600}>Enter more information about the customer</Text>
                        <Input placeholder="Optional" onChange={(e) => {setCustomerInfo((prev:any) => ({...prev, details: e.target.value}))}} value={customerInfo.details} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {generateConversation(true)}} isLoading={loading} variant="outline" colorScheme="blue">Start conversation</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <DummyCall campaignData={campaignData} openDummyCall={openDummyCall} setOpenDummyCall={setOpenDummyCall} campaignId={campaignId} />
            <ConfigPrompt openConfigPrompt={openConfigPrompt} setOpenConfigPrompt={setOpenConfigPrompt} campaignData={campaignData} campaignId={campaignId} setPrevData={setPrevData} />
        </Box>
    )
}