import CampaignsPage from "./pages/Campaigns";
import { ChakraProvider, Spinner, Center, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { auth, firestoreDb } from "./firebase";
import AuthenticationPage from "./pages/Login";
import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import CampaignDashboardPage from "./pages/CampaignDashboard";
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import { useProfileState } from "./hooks/useProfileState";
import BillingModal from "./components/BillingModal";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

function LoadingIndicator() {
  return (
    <Center h="100vh">
      <Spinner size="xl" />
    </Center>
  );
}

export default function App() {
  const [userAuth, setUserAuth] = useState<boolean | null>(null);
  const [allCampaigns, setAllCampaigns] = useState<any>({});
  const {set: setProfile, data: profile} = useProfileState();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let unsubscribeFirestore = () => {};
    let unsubscribeUserInfoListener = () => {};
    let unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setUserAuth(true);
        const userRef = doc(firestoreDb, "users", user.uid);
        setProfile({ user: user });
        unsubscribeUserInfoListener = onSnapshot(
          userRef,
          (userInfoDoc) => {
            if (!userInfoDoc.exists) {
              return;
            }
            setLoading(false);
            setProfile({
              id: userInfoDoc.id,
              user,
              ...userInfoDoc.data()
            });
          },
          (error) => {
            setLoading(false);
          }
        );
        const campaignsCollectionRef = collection(firestoreDb, "campaigns");
        const q = query(
          campaignsCollectionRef,
          where("allowedAccess", "array-contains", user.uid)
        );

        unsubscribeFirestore = onSnapshot(
          q,
          (querySnapshot) => {
            const fetchedCampaigns = {};
            querySnapshot.forEach((doc) => {
              fetchedCampaigns[doc.id] = doc.data();
            });
            setAllCampaigns(fetchedCampaigns);
          },
          (error) => {
            console.error("Error fetching documents:", error);
          }
        );
      } else {
        setUserAuth(false);
        setLoading(false);
        setProfile(null)
      }
    });

    // Cleanup function
    return () => {
      unsubscribeFirestore();
      unsubscribeAuth();
      unsubscribeUserInfoListener();
    };
  }, [setProfile]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        {loading ? (
          <LoadingIndicator />
        ) : !userAuth ? (
          <AuthenticationPage />
        ) : (
          <>
            {profile?.status === "unpaid" && (
              <Alert status='warning' position="sticky" top={0}>
                <AlertIcon />
                <AlertTitle>Payment failed with the payment method you provided</AlertTitle>
              </Alert>
            )}
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={<CampaignsPage allCampaigns={allCampaigns} />}
                />
                <Route
                  path="/campaign"
                  element={
                    <CampaignDashboardPage allCampaigns={allCampaigns} />
                  }
                />
              </Routes>
            </Router>
            <BillingModal />
          </>
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
}
