import { useQuery } from '@tanstack/react-query'
import { FaRegCalendarCheck, FaPhone, FaCheckCircle, FaClock } from 'react-icons/fa';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestoreDb } from '../firebase';
import { Box, Stat, StatLabel, StatNumber, SimpleGrid, Spinner, Center, Icon, Flex } from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useState } from 'react';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface Stats {
  totalCalls?: number;
  successCalls?: number;
  averageCallDuration?: number;
  appointments?: number;
}

const fetchStats = async (campaignId: string, startDate?: string, endDate?: string): Promise<Stats> => {
  let statsDocRef;
  if (startDate && endDate) {
    const dailyStatsRef = collection(firestoreDb, 'campaigns', campaignId, 'dailyAnalytics');
    const q = query(dailyStatsRef, where('date', '>=', startDate), where('date', '<=', endDate));
    const querySnapshot = await getDocs(q);
    const aggregatedStats: Stats = { totalCalls: 0, successCalls: 0, averageCallDuration: 0, appointments: 0 };
    let totalDuration = 0;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      aggregatedStats.totalCalls += data.totalCalls || 0;
      aggregatedStats.successCalls += data.successCalls || 0;
      aggregatedStats.appointments += data.appointments || 0;
      totalDuration += (data.averageCallDuration || 0) * (data.totalCalls || 0);
    });
    aggregatedStats.averageCallDuration = aggregatedStats?.totalCalls > 0 ? Math.round(totalDuration / aggregatedStats.totalCalls) : 0;
    return aggregatedStats;
  } else {
    statsDocRef = doc(firestoreDb, 'campaigns', campaignId, 'analytics', 'totalStats');
  }
  const statsSnapshot = await getDoc(statsDocRef);
  return (statsSnapshot.exists() ? statsSnapshot.data() : {}) as Stats;
};

const StatsCards = ({campaignId}: {
  campaignId: string;
}) => {
  const [filter, setFilter] = useState('all');
  const { data: stats, isLoading, error } = useQuery({
    queryKey: ['stats', campaignId, filter],
    queryFn: () => {
      switch (filter) {
        case 'today':
          const today = dayjs().format('MM-DD-YYYY');
          return fetchStats(campaignId, today, today);
        case 'yesterday':
          const yesterday = dayjs().subtract(1, 'day').format('MM-DD-YYYY');
          return fetchStats(campaignId, yesterday, yesterday);
        case 'last7days':
          const last7Days = dayjs().subtract(6, 'days').format('MM-DD-YYYY');
          return fetchStats(campaignId, last7Days, dayjs().format('MM-DD-YYYY'));
        case 'monthtodate':
          const monthToDate = dayjs().startOf('month').format('MM-DD-YYYY');
          return fetchStats(campaignId, monthToDate, dayjs().format('MM-DD-YYYY'));
        default:
          return fetchStats(campaignId);
      }
    }
  });

  if (isLoading) return (
    <Center h="full">
      <Spinner size="xl" />
    </Center>
  );
  if (error) return <div>Error: {error.message}</div>;


  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}min${minutes > 1 ? 's' : ''} ${remainingSeconds}s`;
  };

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          size="md"
          width="170px"
          marginBottom="4"
        >
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last7days">Last 7 days</option>
          <option value="monthtodate">Month to date</option>
        </Select>
      </Flex>
      <SimpleGrid columns={4} spacing={10}>
        <Box p="5" shadow="md" borderWidth="1px">
          <Stat>
            <StatLabel display="flex" alignItems="center"><Icon as={FaRegCalendarCheck} mr={2} w={5} h={5} color="blue.500" />Appointments</StatLabel>
            <StatNumber>{stats?.appointments || 0}</StatNumber>
          </Stat>
        </Box>
        <Box p="5" shadow="md" borderWidth="1px">
          <Stat>
            <StatLabel display="flex" alignItems="center"><Icon as={FaPhone} mr={2} w={5} h={5} color="blue.500" />Total Calls</StatLabel>
            <StatNumber>{stats?.totalCalls || 0}</StatNumber>
          </Stat>
        </Box>
        <Box p="5" shadow="md" borderWidth="1px">
          <Stat>
            <StatLabel display="flex" alignItems="center"><Icon as={FaCheckCircle} mr={2} w={5} h={5} color="blue.500" />Successful Calls</StatLabel>
            <StatNumber>{stats?.successCalls || 0}</StatNumber>
          </Stat>
        </Box>
        <Box p="5" shadow="md" borderWidth="1px">
          <Stat>
            <StatLabel display="flex" alignItems="center"><Icon as={FaClock} mr={2} w={5} h={5} color="blue.500" />Average Call Duration</StatLabel>
            <StatNumber>{stats?.averageCallDuration ? formatDuration(stats?.averageCallDuration) : '0s'}</StatNumber>
          </Stat>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default StatsCards
