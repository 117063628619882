
import { create } from 'zustand'

interface BillingModalState {
  visible: boolean;
  setVisible: (visible: boolean) => void
}


export const useBillingModalState = create<BillingModalState>((set) => ({
  visible: false,
  setVisible: (visible) => set({ visible: visible }),
}))