import { fetcher } from '../utils/api/fetcher';
import { CustomerInterface } from "../utils/interfaces";

export default async function fetchCallCustomer(campaignId:string, customerInfo:CustomerInterface, toast:any) {
    const url =  process.env.REACT_APP_BACKEND_URL
    
    const body = {
        campaignId,
        customerInfo,
        url: url?.replace(/^(http:\/\/|https:\/\/)/, '')
    }
    
    const response = await fetcher('/make_call', {
        method: 'POST',
        body: JSON.stringify(body)
    });

    const res = await response.json();
    if (res.status === 'error') {
        toast({
            title: 'Error',
            description: res.message,
            status: 'error',
            duration: 5000,
            isClosable: true
        })
        return false
    }
    if (res.callKey) {
        toast({
            title: 'Calling customer',
            description: 'Please wait while we connect you to the customer',
            status: 'info',
            duration: 5000,
            isClosable: true
        })
        return res.callKey
    }

}
