import { Flex, Select, Stack, Text, Input, Button } from "@chakra-ui/react";
import { allTimezones } from "../utils/others";
import { CampaignDataInterface } from '../utils/interfaces';

export default function DealershipOpeningHours(
    { dealTime, onDealTimeChange }: 
    { dealTime: CampaignDataInterface['dealTime'], onDealTimeChange: (newDealTime: CampaignDataInterface['dealTime']) => void }
) {
    const { timezone, from, to } = dealTime || {};
    const weekend = dealTime?.weekend || { saturday: { from: '', to: ''}, sunday: { from: '', to: ''} };
    const { saturday, sunday } = weekend;
    
    const updateWeekend = (day: 'saturday' | 'sunday', updates: any) => {
        onDealTimeChange({
            ...dealTime,
            weekend: {
                ...weekend,
                [day]: {
                    ...weekend?.[day],
                    ...updates,
                },
            },
        });
    };

    const clearWeekendDay = (day: 'saturday' | 'sunday') => {
        onDealTimeChange({
            ...dealTime,
            weekend: {
                ...weekend,
                [day]: { from: '', to: '' },
            },
        });
    };

    const handleTimezoneChange = (e) => onDealTimeChange({ ...dealTime, timezone: e.target.value });

    const handleWeekdayFromChange = (e) => onDealTimeChange({ ...dealTime, from: e.target.value });

    const handleWeekdayToChange = (e) => onDealTimeChange({ ...dealTime, to: e.target.value });

    const handleSaturdayFromChange = (e) => updateWeekend('saturday', { from: e.target.value });

    const handleSaturdayToChange = (e) => updateWeekend('saturday', { to: e.target.value });

    const clearSaturday = () => clearWeekendDay('saturday');

    const handleSundayFromChange = (e) => updateWeekend('sunday', { from: e.target.value });

    const handleSundayToChange = (e) => updateWeekend('sunday', { to: e.target.value });

    const clearSunday = () => clearWeekendDay('sunday');

    return (
      <>
        <Flex marginTop="5%" marginBottom="2%">
            <Text marginTop="auto" marginBottom="auto" w="50%" fontWeight={400} fontSize="16px">Dealership's opening hours</Text>
            <Select value={timezone} onChange={handleTimezoneChange}  marginTop="auto" marginBottom="auto" size="sm" borderRadius="20px"
                {...(!timezone ? {placeholder: "Select timezone"}: {})}
            >
                {allTimezones.map((time) => {
                    return <option key={time.value} value={time.value}>{time.label}</option>
                })}
            </Select>
        </Flex>
        <Stack spacing="3">
            <Flex>
                <Text w="100px" marginTop="auto" marginBottom="auto" marginRight="1%" fontSize="12px" fontWeight={600} color="gray">Weekdays</Text>
                <Input w="180px"  value={from} onChange={handleWeekdayFromChange} marginRight="2%" placeholder="From" type="time" />
                <Text marginTop="auto" marginBottom="auto" marginRight="1%" fontSize="12px" fontWeight={600} color="gray">-</Text>
                <Input w="180px" value={to} onChange={handleWeekdayToChange} marginLeft="2%" placeholder="To" type="time" />
            </Flex>
            <Flex alignItems="center">
                <Text w="100px"  marginRight="1%" fontSize="12px" fontWeight={600} color="gray">Saturday</Text>
                <Input w="180px" value={saturday?.from || ''} onChange={handleSaturdayFromChange} marginRight="2%" placeholder="From" type="time" />
                <Text marginRight="1%" fontSize="12px" fontWeight={600} color="gray">-</Text>
                <Input  w="180px"  value={saturday?.to || ''} onChange={handleSaturdayToChange} marginLeft="2%" placeholder="To" type="time" />
                <Button ml="2" onClick={clearSaturday}>Clear</Button>
            </Flex>
            <Flex alignItems="center">
                <Text w="100px"  marginRight="1%" fontSize="12px" fontWeight={600} color="gray">Sunday</Text>
                <Input  w="180px" value={sunday?.from || ''} onChange={handleSundayFromChange} marginRight="2%" placeholder="From" type="time" />
                <Text marginRight="1%" fontSize="12px" fontWeight={600} color="gray">-</Text>
                <Input  w="180px" value={sunday?.to || ''} onChange={handleSundayToChange} marginLeft="2%" placeholder="To" type="time" />
                <Button ml="2" onClick={clearSunday}>Clear</Button>
            </Flex>
        </Stack>
        </>
    );
}
