import { Avatar, Box, Button, Center, InputLeftAddon, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, Text, ModalOverlay, useToast, FormControl, FormLabel, InputGroup, Tabs, TabList, TabPanel, TabPanels, Tab, Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react";
import fetchCallCustomer from "../helpers/fetchCallCustomer";
import { useEffect, useState } from "react";
import { Icon, PhoneIcon, RepeatClockIcon } from "@chakra-ui/icons";
import { firestoreDb } from "../firebase";
import { CallDataInterface, DummyCallInterface } from "../utils/interfaces";
import fetchTerminateCall from "../helpers/fetchTerminateCall";
import { collection, doc, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { PhoneInput, defaultCountries, parseCountry } from "react-international-phone";

export default function DummyCall({openDummyCall, setOpenDummyCall, campaignData, campaignId}: DummyCallInterface) {
    const [dummyName, setDummyName] = useState<string>('');
    const [dummyPhone, setDummyPhone] = useState<string>('');
    const [dummyProduct, setDummyProduct] = useState<string>(campaignData.defaultProduct ? campaignData.defaultProduct : '');
    const [loadingCall, setLoadingCall] = useState<boolean>(false);
    const [currentCall, setCurrentCall] = useState<string>('');
    const [realTimeConv, setRealTimeConv] = useState<CallDataInterface>();
    const [lastCalls, setLastCalls] = useState<CallDataInterface[]>([]);
    const toast = useToast();

    useEffect(() => {
        const callsRef = collection(firestoreDb, 'campaigns', campaignId, 'calls');
        const q = query(callsRef, orderBy('endCall', 'desc'), limit(10));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                const callsData = snapshot.docs.map((doc) => ({ ...doc.data()}));
                setLastCalls(callsData);
            } else {
                setLastCalls([]);
            }
        });
        return unsubscribe;
    }, [campaignId]);
    
    function callCustomer(){
        setLoadingCall(true);
        if (dummyPhone.length !== 12) {
            toast({
                title: "Invalid phone number",
                description: "Please enter a valid phone number",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            setLoadingCall(false);
            return;
        }
        fetchCallCustomer(campaignId, {name: dummyName, phone_number: dummyPhone, product: dummyProduct}, toast).then((callKey) => {
            setLoadingCall(false)
            if (callKey) {
                setCurrentCall(callKey);
                const callRef = doc(firestoreDb, 'campaigns', campaignId, 'calls', callKey);
                onSnapshot(callRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.data();
                        setRealTimeConv(data);
                        if (data.endCall) {
                            setCurrentCall('');
                        }
                    }
                })
            }   
        }
        )
    }

    function chatScreen(conv:CallDataInterface) {
        return (
        <Box
        w="full"
        overflowY="auto"
        h="43vh"
        padding="4"
        marginTop="4"
        border="1px solid gray"
        borderRadius="md"
        bg="gray.50"
        >
        {conv.dialogue && conv.dialogue.map((message, index) => (
            <Flex
            key={index}
            justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
            marginTop="2"
            >
            {message.role === 'assistant' && (
                <Avatar
                name={campaignData.repName}
                size="sm"
                marginRight="2"
                />
            )}
            <Box
                bg={message.role === 'assistant' ? "blue.100" : "white"}
                boxShadow="md"
                border="1px solid blue.200"
                borderRadius="lg"
                padding="2"
                maxWidth="70%"
            >
                <Text fontSize="sm" fontWeight="bold">
                {message.role === 'user' ? dummyName : campaignData.repName}
                </Text>
                <Text color="gray.600" fontSize="xs">
                {message.content}
                </Text>
            </Box>
            {message.role === 'user' && <Avatar name={dummyName} size="sm" marginLeft="2" />}
            </Flex>
        ))}
        </Box>)
    }

    return (
        <Modal closeOnOverlayClick={false} isOpen={openDummyCall} onClose={() => setOpenDummyCall(false)}>
            <ModalOverlay />
            <ModalContent padding="3">
                <Tabs variant="soft-rounded" colorScheme="blackAlpha">
                    <TabList>
                        <Tab>
                            <Icon as={PhoneIcon} mr="2" />
                            <Text>Call Yourself</Text>
                        </Tab>
                        <Tab>
                            <Icon as={RepeatClockIcon} mr="2" />
                            <Text>Last Calls</Text>
                        </Tab>
                    </TabList>
                    <ModalCloseButton marginTop="-2%" />
                    <TabPanels>
                        <TabPanel>
                            <ModalBody padding={2}>
                            <FormControl id="phone-number" isRequired marginTop="4">
                                <FormLabel>Phone Number</FormLabel>
                                <PhoneInput
                                forceDialCode={true}
                                defaultCountry="us"
                                value={dummyPhone}
                                onChange={(phone) => setDummyPhone(phone)}
                                inputStyle={{width: '100%', fontSize:"15px", background:"transparent", height:"40px"}}
                                countrySelectorStyleProps={{buttonStyle: {background: 'transparent', height: '40px'}}}
                                countries={defaultCountries.filter((country) => {
                                    const { iso2 } = parseCountry(country);
                                    return ['us'].includes(iso2);
                                })}
                                />
                            </FormControl>
                                <FormControl id="name" isRequired marginTop="4">
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                    value={dummyName}
                                    onChange={(e) => setDummyName(e.target.value)}
                                    placeholder="Enter your name"
                                    />
                                </FormControl>
                                <FormControl id="product-interest" isRequired marginTop="4">
                                    <FormLabel>Product or Service</FormLabel>
                                    <Input
                                    value={dummyProduct}
                                    onChange={(e) => setDummyProduct(e.target.value)}
                                    placeholder="Enter a product or service of interest"
                                    />
                                </FormControl>
                                {currentCall === '' ? (
                                    <Center h="100%" marginTop="4">
                                    <Button
                                        leftIcon={<PhoneIcon />}
                                        colorScheme="teal"
                                        variant="solid"
                                        onClick={callCustomer}
                                        isLoading={loadingCall}
                                    >
                                        Call
                                    </Button>
                                    </Center>
                                ) : (
                                    realTimeConv && chatScreen(realTimeConv)
                                )}
                                {currentCall !== '' && (
                                    <Center marginTop="4">
                                        <Button
                                        onClick={() => {fetchTerminateCall(currentCall, campaignId); setCurrentCall('')}}
                                        variant="outline"
                                        colorScheme="red"
                                        leftIcon={<PhoneIcon />}
                                        >
                                        End Call
                                        </Button>
                                    </Center>
                                )}
                                </ModalBody>
                            </TabPanel>
                            <TabPanel>
                                <ModalBody padding={2}>
                                    {lastCalls.length > 0 ? (
                                        <Box>
                                            <Accordion>
                                                {lastCalls.map((call: any, index: number) => (
                                                <AccordionItem key={index}>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box as="span" flex='1' textAlign='left' fontWeight={700} color="blue.900">
                                                                {`${call.toName} - ${call.product ? call.product : ''} (${new Date(call.endCall * 1000).toLocaleString()})`}
                                                            </Box>
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel>
                                                    <Center>
                                                        <audio controls src={call.audioUrl}>
                                                            <a href={call.audioUrl}> Download audio </a>
                                                        </audio>
                                                    </Center>
                                                        {chatScreen(call)}
                                                    </AccordionPanel>
                                                </AccordionItem>))}
                                            </Accordion>
                                        </Box>
                                    ) : (
                                    <Text>Your last calls will be displayed here</Text>
                                    )}
                                </ModalBody>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                
            </ModalContent>
        </Modal>
    )
}