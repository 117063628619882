import { fetcher } from '../utils/api/fetcher';

export default async function fetchParseCustomersCsv(url:string, campaignId:string, selectedAudience:string, deletePreviousCustomers:boolean) {
    const body = {
        url,
        campaignId,
        selectedAudience,
        deletePreviousCustomers
    }
    const response = await fetcher(`/parse_customer_csv`, {
        method: 'POST',
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error('Failed to parse csv');
    }

    return response.json();
}
