import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs, endBefore, limitToLast, startAt, endAt, where } from 'firebase/firestore';
import { firestoreDb } from '../firebase'; 
import RecordingRow from '../components/RecordingRow';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { DatePicker } from '../components/DatePicker';
import { capitalize } from '../utils/others';


export default function RecordingsPage({ campaignId }: { campaignId: string; }) {
  const [page, setPage] = useState(0);
  const pageSize = 20;
  const [calls, setCalls] = useState<any[]>([]);
  const [firstDoc, setFirstDoc] = useState<any>(null);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [selectedDate, setSelectedDate] = useState<null | Date>();

  useEffect(() => {
    setLoading(true);
    const callsRef = collection(firestoreDb, `campaigns/${campaignId}/calls`);
    let q
    if (searchName.length > 0) {
      q = query(
        callsRef, 
        orderBy('toName'), 
        orderBy('startCall', 'desc'), 
        startAt(capitalize(searchName)), 
        endAt(capitalize(searchName) + '\uf8ff'), 
        limit(pageSize)
      );
    } else if (selectedDate) {
      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
      q = query(
        callsRef, 
        orderBy('startCall', 'desc'), 
        where('startCall', '>=', startOfDay),
        where('startCall', '<=', endOfDay),
        limit(pageSize)
      );
    } else {
      q = query(callsRef, orderBy('startCall', 'desc'), limit(pageSize));
    }
    getDocs(q).then((snapshot) => {
      const newCalls = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCalls(newCalls);
      setFirstDoc(snapshot.docs[0]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setLoading(false);
    });
  }, [campaignId, searchName, selectedDate]);

  const handlePrevPage = () => {
    if (page === 0 || !firstDoc) return;
    setLoading(true);
    const callsRef = collection(firestoreDb, `campaigns/${campaignId}/calls`);
    let q;
    if (selectedDate) {
      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
      q = query(
        callsRef, 
        orderBy('startCall', 'desc'), 
        where('startCall', '>=', startOfDay),
        where('startCall', '<=', endOfDay),
        endBefore(firstDoc),
        limitToLast(pageSize)
      );
    } else if (searchName.length > 0) {
      q = query(
        callsRef, 
        orderBy('toName'), 
        orderBy('startCall', 'desc'), 
        endBefore(firstDoc),
        limitToLast(pageSize),
        startAt(capitalize(searchName)), 
        endAt(capitalize(searchName) + '\uf8ff'), 
        limit(pageSize)
      );
    } else {
      q = query(callsRef, orderBy('startCall', 'desc'), endBefore(firstDoc), limitToLast(pageSize));
    }
    getDocs(q).then((snapshot) => {
      const newCalls = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPage((prev) => prev - 1);
      setCalls(newCalls);
      setFirstDoc(snapshot.docs[0]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setLoading(false);
    });
  };

  const handleNextPage = () => {
    if (calls.length < pageSize || !lastDoc) return;
    setLoading(true);
    const callsRef = collection(firestoreDb, `campaigns/${campaignId}/calls`);
    let q
    if (selectedDate) {
      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
      q = query(
        callsRef, 
        orderBy('startCall', 'desc'), 
        where('startCall', '>=', startOfDay),
        where('startCall', '<=', endOfDay),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else if (searchName.length > 0) {
      q = query(
        callsRef, 
        orderBy('toName'), 
        orderBy('startCall', 'desc'), 
        startAfter(lastDoc),
        limitToLast(pageSize),
        startAt(capitalize(searchName)), 
        endAt(capitalize(searchName) + '\uf8ff'), 
        limit(pageSize)
      );
    } else {
      q = query(callsRef, orderBy('startCall', 'desc'), limit(pageSize), startAfter(lastDoc));
    }
    getDocs(q).then((snapshot) => {
      const newCalls = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      if (newCalls.length > 0) {
        setPage((prev) => prev + 1);
        setCalls(newCalls);
        setFirstDoc(snapshot.docs[0]);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
      setLoading(false);
    });
  };



  return (
    <Box padding="5%" paddingTop="2%" overflowY="auto" bg="#fbfafb" w="85%" h="100vh">
      <Flex  alignItems="center" mb="20px" justifyContent="space-between">
        <Text  fontSize={35} fontWeight={100} >
          Recordings
        </Text>
      </Flex>
      <Box padding="20px" w="100%" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
      <Flex mb="20px">
          <InputGroup   minW="200px" maxW="300px" mr="10px" size="sm">
              <InputLeftElement>
                  <SearchIcon color='gray.300' />
              </InputLeftElement>
              <Input isDisabled={!!selectedDate} value={searchName} onChange={(e) => {setSearchName(e.target.value)}} size="sm" bg="white" borderRadius="20px" placeholder="Search by name"/>
          </InputGroup>
          
          <DatePicker
            onChange={setSelectedDate}
            placeholderText='Select Date'
            selected={selectedDate}
            disabled={searchName?.length > 0}
          />
        </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Customer Name</Th>
            <Th>Phone Number</Th>
            <Th>Date</Th>
            <Th>Recording</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={3}>
                <Spinner />
              </Td>
            </Tr>
          ) : calls.length === 0 ? (
            <Tr h={100}>
              <Td colSpan={3} textAlign="center">
                No recordings found.
              </Td>
            </Tr>
          ) : (
            calls.map((call) => <RecordingRow key={call.id} call={call} />)
          )}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button
          variant="outline"
          onClick={handlePrevPage}
          isDisabled={page === 0 || loading || !firstDoc}
          leftIcon={<ChevronLeftIcon />}
          >
            Previous
          </Button>
        <Button
          variant="outline"
          onClick={handleNextPage}
          isDisabled={calls.length < pageSize || loading || !lastDoc}
          rightIcon={<ChevronRightIcon />}
          >
            Next
          </Button>
      </Box>
    </Box>
  </Box>
);
}
