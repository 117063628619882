import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

type WeekdayPickerProps = {
  days: string[];
  setDays: (selectedDays: string[]) => void;
};

const WeekdayPicker: React.FC<WeekdayPickerProps> = ({ days, setDays }) => {
  const handleWeekdayChange = (selectedDays: string[]) => {
    setDays(selectedDays);
  };

  return (
    <FormControl mt={5}>
      <FormLabel mb={2}>Schedule Auto Dialer</FormLabel>
      <CheckboxGroup
        colorScheme="blue"
        onChange={handleWeekdayChange}
        value={days}
      >
        <Stack spacing={[1, 5]} direction={["column", "row"]}>
          <Checkbox value="monday">Mon</Checkbox>
          <Checkbox value="tuesday">Tue</Checkbox>
          <Checkbox value="wednesday">Wed</Checkbox>
          <Checkbox value="thursday">Thu</Checkbox>
          <Checkbox value="friday">Fri</Checkbox>
          <Checkbox value="saturday">Sat</Checkbox>
          <Checkbox value="sunday">Sun</Checkbox>
        </Stack>
      </CheckboxGroup>
    </FormControl>
  );
};

type TimeRange = { startTime: string; endTime: string };
type TimePickerProps = {
  label: string;
  timeRanges: TimeRange[];
  setTimeRanges: (newTimeRanges: TimeRange[]) => void;
};

const TimePicker: React.FC<TimePickerProps> = ({ label, timeRanges, setTimeRanges }) => {
  const handleTimeChange = (index: number, field: 'startTime' | 'endTime') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index] = { ...newTimeRanges[index], [field]: event.target.value };
    setTimeRanges(newTimeRanges);
  };

  const addTimeRange = () => setTimeRanges([...timeRanges, { startTime: "", endTime: "" }]);
  const removeTimeRange = (index: number) => {
    const newTimeRanges = timeRanges.filter((_, i) => i !== index);
    setTimeRanges(newTimeRanges);
  };

  return (
    <FormControl mt={5}>
      <FormLabel mb={2}>{label}</FormLabel>
      {timeRanges.map((range, index) => (
        <Box key={index} display="flex" alignItems="end" mb={5}>
          <Box mr={5}>
            <FormLabel fontSize="sm" fontWeight="normal">Start Time</FormLabel>
            <Input type="time" value={range.startTime} onChange={handleTimeChange(index, 'startTime')} width="200" />
          </Box>
          <Box mr={5}>
            <FormLabel fontSize="sm" fontWeight="normal">End Time</FormLabel>
            <Input type="time" value={range.endTime} onChange={handleTimeChange(index, 'endTime')} width="200" />
          </Box>
          <Button  ml={2} onClick={() => removeTimeRange(index)}>
            Remove
          </Button>
        </Box>
      ))}
      <Button onClick={addTimeRange}>Add Time Range</Button>
    </FormControl>
  );
};

export type AutoDialerScheduleType = {
  [key: string]: TimeRange[]; // day: [TimeRange]
};

type AutoDialerConfigProps = {
  autoDialerSchedule?: AutoDialerScheduleType;
  setAutoDialerSchedule: (config: AutoDialerScheduleType) => void;
};

const defaultAutoDialerSchedule: AutoDialerScheduleType = {};

const AutoDialerSchedule: React.FC<AutoDialerConfigProps> = ({
  autoDialerSchedule = defaultAutoDialerSchedule,
  setAutoDialerSchedule,
}) => {
  const { saturday, sunday, ...weekdays } = autoDialerSchedule;

  const setWeekdayDays = (selectedDays: string[]) => {
    const newSchedule = selectedDays.reduce((schedule, day) => {
      if (day === 'saturday' || day === 'sunday') {
        schedule[day] = autoDialerSchedule[day] || [];
      } else {
        const defaultTimeRanges = Object.values(weekdays).find(timeRanges => timeRanges.length > 0 && timeRanges[0].startTime) || [{ startTime: "", endTime: "" }];
        schedule[day] = autoDialerSchedule[day] || defaultTimeRanges;
      }
      return schedule;
    }, {} as AutoDialerScheduleType);

    setAutoDialerSchedule(newSchedule);
  };

  const setWeekdayTimeRanges = (newTimeRanges: TimeRange[]) => {
    const newState = { ...weekdays };
    Object.keys(newState).forEach((key) => {
      newState[key] = newTimeRanges;
    });

    setAutoDialerSchedule({ ...autoDialerSchedule, ...newState });
  };

  const setWeekendSaturday = (newTimeRanges: TimeRange[]) =>
    setAutoDialerSchedule({ ...autoDialerSchedule, saturday: newTimeRanges });

  const setWeekendSunday = (newTimeRanges: TimeRange[]) =>
    setAutoDialerSchedule({ ...autoDialerSchedule, sunday: newTimeRanges });

  return (
    <Box>
      <WeekdayPicker
        days={Object.keys(autoDialerSchedule)}
        setDays={setWeekdayDays}
      />
      {Object.keys(weekdays).length > 0 && (
        <TimePicker
          label="Weekday Hours"
          timeRanges={Object.values(weekdays)[0] || [{ startTime: "", endTime: "" }]}
          setTimeRanges={setWeekdayTimeRanges}
        />
      )}
      {saturday && (
        <TimePicker
          label="Saturday Hours"
          timeRanges={saturday}
          setTimeRanges={setWeekendSaturday}
        />
      )}
      {sunday && (
        <TimePicker
          label="Sunday Hours"
          timeRanges={sunday}
          setTimeRanges={setWeekendSunday}
        />
      )}
    </Box>
  );
};

export default AutoDialerSchedule;
