import { fetcher } from '../utils/api/fetcher';

export default async function fetchGetEmailFromUid(uids: string[]) {
    const body = {
        uids
    }
    const response = await fetcher('/get_email_from_uid', {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return response.json();
}
