import { AddIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Box, IconButton, Flex, Text, Wrap, Divider, Spinner, Spacer, Tooltip, Menu, MenuButton, MenuItem, MenuList, Tag } from '@chakra-ui/react'
import NewProjectWindow from '../components/newProjectWindow';
import {useState} from 'react'
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { BsPeople } from 'react-icons/bs';
import ManageAccountWindow from '../components/manageAccountWindow';
import CampaignCard from '../components/campaignCard';
import { signOut } from 'firebase/auth';
import { useBillingModalState } from '../hooks/useBillingModalState';
import { useProfileState } from '../hooks/useProfileState';


export default function CampaignsPage({allCampaigns}:any){
    const [openNewProject, setOpenNewProject] = useState<boolean>(false);
    const [hoveredProject, setHoveredProject] = useState<string>('');
    const [openManageAccount, setOpenManageAccount] = useState<boolean>(false);
    const navigate = useNavigate();
    const {setVisible: setBillingModalVisible} = useBillingModalState();
    const profile = useProfileState(state => state.data);

    const onOpenBillingModal = () => setBillingModalVisible(true);

    const isUnlimitedPlan = profile?.plan?.creditMinutes === 1000000;

    return (
        <Box h="100vh" padding="1%" bg="white">
            <Box h="7vh">
                <Flex alignItems="center">
                    <Text textAlign="center" fontSize="30px" fontWeight={200} color="#4A5568" ml="2%">Ikonic.AI</Text>
                    <Spacer />
                    {profile?.plan && (
                        <Tag colorScheme={profile?.remainingPlanMinutes < 1 ? 'red' : 'gray'}>
                            Credits: <Text mr={0.5} ml={2} fontWeight="semibold">
                                {
                                    isUnlimitedPlan ? 'Unlimited' : `${profile?.remainingPlanMinutes} mins`
                                }
                            </Text>
                        </Tag>
                    )}
                    <Tooltip label="Manage Access">
                        <IconButton
                            onClick={() => {setOpenManageAccount(true)}}
                            aria-label='Manage Access'
                            icon={<BsPeople />}
                            variant="ghost"
                            fontSize="20px"
                        />
                    </Tooltip>
                    <Tooltip label="Create new campaign">
                        <IconButton 
                            onClick={() => setOpenNewProject(true)}
                            aria-label="New campaign"
                            icon={<AddIcon />}
                            variant="ghost"
                            
                        />
                    </Tooltip>
                    <Menu>
                        <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="ghost" />
                        <MenuList>
                            <MenuItem onClick={onOpenBillingModal}>Billing</MenuItem>
                            <MenuItem onClick={() => {signOut(auth)}}>Log out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Box>
            {allCampaigns && [...new Set(Object.keys(allCampaigns).map(campaignId => allCampaigns[campaignId].dealName))].map((dealName, idx) => (
                <Box key={idx}>
                    <Divider border="1px solid #CBD5E0" marginBottom=".5%" />
                    <Text fontSize="2xl" fontWeight={600} color="#4A5568" ml="2%">{dealName}</Text>
                    <Wrap padding="2%" spacing="4vw" minH="350px">
                    {allCampaigns ? Object.keys(allCampaigns).filter((campaignId) => allCampaigns[campaignId].dealName === dealName).map((campaignId) => (
                        <CampaignCard
                            allCampaigns={allCampaigns}
                            campaignId={campaignId}
                            setHoveredProject={setHoveredProject}
                            hoveredProject={hoveredProject}
                            navigate={navigate}
                        />
                    )) : <Spinner />}
                    </Wrap>
                </Box>
                ))}
            <NewProjectWindow openNewProject={openNewProject} setOpenNewProject={setOpenNewProject} />
            {auth.currentUser && openManageAccount && <ManageAccountWindow openManageAccount={openManageAccount} setOpenManageAccount={setOpenManageAccount} auth={auth} allCampaigns={allCampaigns} />}
        </Box>
    );
}