import { fetcher } from '../utils/api/fetcher';

export default async function fetchUpdateForwardCall(dealPhoneNumber: string, forwardNumber: string) {    
    const body = {
        dealPhoneNumber,
        forwardNumber
    }
    const response = await fetcher(`/update_forward_call`, {
        method: 'POST',
        body: JSON.stringify(body)
    });

    return response.json();
}
