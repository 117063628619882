import { Tr, Td, Spinner } from "@chakra-ui/react";
import { ref as refStorage, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import React, { useState, useEffect, useRef } from "react";

interface RecordingRowProps {
  call: {
    id: string;
    toName: string;
    toNumber: string;
    audioUrl?: string;
    startCall: number;
  };
}

function getPhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith("+1")) {
    return phoneNumber;
  }

  if (phoneNumber.startsWith("1") && phoneNumber.length === 11) {
    return `+${phoneNumber}`;
  }

  return `+1${phoneNumber}`;
}

const RecordingRow: React.FC<RecordingRowProps> = ({ call }) => {
  const [recordingUrl, setRecordingUrl] = useState<string | undefined>(
    call?.audioUrl || ""
  );

  const onLoadedMetadata = (event) => {
    if (event.target.duration < 10) {
      setRecordingUrl(undefined);
    }
  };

  useEffect(() => {
    if (!call.audioUrl) {
      const audioRef = refStorage(storage, `recordings/${call.id}.mp3`);
      getDownloadURL(audioRef)
        .then((url) => {
          setRecordingUrl(url);
        })
        .catch((error) => {
          setRecordingUrl(undefined);
        });
    }
  }, [call]);

  if (recordingUrl === undefined) {
    return null;
  }

  return (
    <Tr>
      <Td w="25%">{call.toName}</Td>
      <Td w="25%">{getPhoneNumber(String(call.toNumber))}</Td>
      <Td w="25%">{new Date(call.startCall * 1000).toLocaleString()}</Td>
      <Td w="25%">
        {recordingUrl ? (
          <audio controls onLoadedMetadata={onLoadedMetadata}>
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        ) : recordingUrl === undefined ? (
          "No recording"
        ) : (
          <Spinner />
        )}
      </Td>
    </Tr>
  );
};

export default RecordingRow;
