
import { create } from 'zustand'

interface ProfileState {
  data: any;
  set: (data: any) => void
  clear: () => void
  update: (data: any) => void
}


export const useProfileState = create<ProfileState>((set) => ({
  data: null,
  set: (data) => set({ data: data }),
  update: (data) => set((state) => ({ data: { ...state.data, ...data } })),
  clear: () => set({ data: null }),
}))