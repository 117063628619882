import { fetcher } from '../utils/api/fetcher';

export default async function fetchTerminateCall(callKey:string, campaignId:string) {    
    const body = {
        callKey,
        campaignId
    }
    const response = await fetcher(`/end_call`, {
        method: 'POST',
        body: JSON.stringify(body)
    });

    return response.json();
}
