import { Flex, Spacer, Button, Box, Text, Center, Input, useToast, Select, Divider, Tag, Wrap, WrapItem, IconButton, InputGroup, InputLeftElement, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Tooltip, FormControl, InputLeftAddon, useClipboard, Stack, InputRightElement } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import DealershipOpeningHours from "../components/DealershipOpeningHours";
import { ConfigDataInterface } from "../utils/interfaces";
import { possibleObjectives } from "../utils/others";
import { AddIcon, DeleteIcon, QuestionIcon, SearchIcon } from "@chakra-ui/icons";
import InstructionsComponent from "../components/instructions";
import { auth, firestoreDb } from "../firebase";
import { collection, deleteDoc, doc, getDocs, updateDoc, writeBatch } from "firebase/firestore";
import { isEqual } from 'lodash';
import { ALL_STATES } from "../utils/others";
import AutoDialerSchedule from '../components/autoDialerSchedule';
import fetchUpdateForwardCall from "../helpers/fetchUpdateForwardCall";
import { PhoneInput, defaultCountries, parseCountry, getActiveFormattingMask } from "react-international-phone";
import 'react-international-phone/style.css';
import { isProduction } from '../utils';
import SelectVoice from '../components/selectRepresentativeVoice';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { fetcher } from '../utils/api/fetcher';

import { useMutation } from '@tanstack/react-query';

function formatPhoneNumber(phoneNumber?: string) {
    if (!phoneNumber) {
        return '';
    }
    // format: '+1 (XXX) XXX-XXXX'
    let formattedPhoneNumber = phoneNumber;

    if (phoneNumber.length >= 10) {
        formattedPhoneNumber = `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8)}`;
    }
    return formattedPhoneNumber;
}

export default function ConfigsPage({campaignData, setCampaignData, prevData, setPrevData, campaignId}:ConfigDataInterface){
    const [showSave, setShowSave] = useState<boolean>(false);
    const [searchInstruction, setSearchInstruction] = useState<string>('');
    const [deleteCampaignWindow, setDeleteCampaignWindow] = useState<boolean>(false);
    const toast = useToast();
    const emailLeadImport = `${campaignId}${isProduction() ? '@email-leads.ikonic.ai': '@email-leads-staging.ikonic.ai'}`;
    const {copy} = useCopyToClipboard();
    const generatePhoneNumberMutation = useMutation(
        {
            mutationFn: () => fetcher(`/generate-new-deal-phone-number`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ campaignId: campaignId }),
            }),
            onSuccess: (data) => {
                toast({
                    title: "New phone number generated.",
                    description: "A new phone number has been successfully generated.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            },
            onError: (error) => {
                toast({
                    title: "Error generating new phone number.",
                    description: error instanceof Error ? error.message : "An unknown error occurred",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            },
        }
    );

    const onHandleCopyEmailLeadImport = () => {
            copy(emailLeadImport)
            toast({
                title: "Email copied.",
                description: "The email has been copied to your clipboard.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
    }

    const onHandleCopyPhoneNumber = () => {
        if (campaignData?.dealPhoneNumber) {
            copy(campaignData.dealPhoneNumber)
            toast({
                title: "Phone number copied.",
                description: "The phone number has been copied to your clipboard.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } 
    }

    useEffect(() => {
        if (!isEqual(campaignData, prevData)) {
            setShowSave(true);
        } else {
            setShowSave(false);
        }
    }, [campaignData, prevData])

    function addObjective(obj:{name:string, emoji:string}){
        if (campaignData.repObjectives.map((x) => x.emoji).includes(obj.emoji)){
            setCampaignData((prev) => ({...prev!, repObjectives: prev!.repObjectives.filter(v => v.emoji !== obj.emoji)}))
        }else{
            setCampaignData((prev) => ({...prev!, repObjectives: [...prev!.repObjectives, obj]}))
        }
    }

    function addRepQuestion(idx:number){
        setCampaignData((prev) => {
            let newRepObjectives = [...prev!.repObjectives];
            if (newRepObjectives[idx].questions){
                newRepObjectives[idx].questions?.push('')
            }else{
                newRepObjectives[idx].questions = [''];
            }
            return {...prev!, repObjectives: newRepObjectives}
        }
        )
    }

    function editQuestion(idxObj:number, idxQ:number, message:string){
        setCampaignData((prev) => {
            let newRepObjectives = [...prev!.repObjectives];
            newRepObjectives[idxObj].questions![idxQ] = message;
            return {...prev!, repObjectives: newRepObjectives}
        })
    }

    function deleteQuestion(idxObj:number, idxQ:number){
        setCampaignData((prev) => {
            let newRepObjectives = [...prev!.repObjectives];
            newRepObjectives[idxObj].questions = newRepObjectives[idxObj].questions?.filter((v,idx) => idx !== idxQ)
            return {...prev!, repObjectives: newRepObjectives}
        }
        )
    }

    function saveChanges() {
        const campaignRef = doc(firestoreDb, 'campaigns', campaignId);
        if (prevData?.forwardNumber !== campaignData.forwardNumber && campaignData.dealPhoneNumber) {
            if (campaignData.forwardNumber.length !== 12) {
                toast({
                    title: "Error updating forward number.",
                    description: "The forward number must be a valid phone number.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }
            fetchUpdateForwardCall(campaignData.dealPhoneNumber, campaignData.forwardNumber)
        }

        updateDoc(campaignRef, campaignData).then(() => {
            toast({
                title: "Changes saved.",
                description: "Your changes have been saved.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    
    async function deleteCollectionInBatches(db, collectionPath, batchSize = 500) {
        const collectionRef = collection(db, collectionPath);
        let querySnapshot = await getDocs(collectionRef);
    
        while (!querySnapshot.empty) {
            const batch = writeBatch(db);
            querySnapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });
            await batch.commit();
            
            querySnapshot = await getDocs(collectionRef); // Check for any documents still remaining
        }
    }
    
    // Main function to delete a document and its known subcollections
    async function deleteDocumentAndSubcollections(db, docPath, subcollections) {
        for (const subcollection of subcollections) {
            const subcollectionPath = `${docPath}/${subcollection}`;
            await deleteCollectionInBatches(db, subcollectionPath);
        }
        
        // After all subcollections are deleted, delete the parent document
        await deleteDoc(doc(db, docPath));
    }

    function deleteCampaign() {
        const subcollections = ['calls', 'customers', 'assistantsChats', 'simulations']; 
        if (auth.currentUser.uid === campaignData.createdBy) {
            deleteDocumentAndSubcollections(firestoreDb, `campaigns/${campaignId}`, subcollections).then(() => {
                toast({
                    title: "Campaign deleted.",
                    description: "Your campaign has been deleted.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                window.location.href = '/#';
            }).catch((error) => {
                console.error("Error updating document: ", error);
            });
        } else {
            toast({
                title: "Error deleting campaign.",
                description: "You can't delete a campaign you didn't create.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }


    return (
        <Box padding="5%" paddingTop="2%" overflowY="auto" bg="#fbfafb" w="85%" h="100vh">
            <Flex>
                <Text fontSize={35} fontWeight={100}>Configurations</Text>
                <Spacer />
                {showSave && <Button onClick={() => {saveChanges(); setShowSave(false);}} colorScheme="blue">Save changes</Button>}
            </Flex>
            <Center>
                <Box padding="3%" w="100%" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                    <Text fontSize={25} fontWeight={200}>Representative and Dealership</Text>
                    <Divider />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Representative's name</Text>
                    <Input value={campaignData.repName} onChange={(e) => {setCampaignData((prev) => ({...prev!, repName: e.target.value}))}} placeholder="Representative name" />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Representative's Manager</Text>
                    <Input value={campaignData.repManager} onChange={(e) => {setCampaignData((prev) => ({...prev!, repManager: e.target.value}))}} placeholder="Name of Representative's Manager" />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Dealership's name</Text>
                    <Input value={campaignData.dealName} onChange={(e) => {setCampaignData((prev) => ({...prev!, dealName: e.target.value}))}} placeholder="Dealership name" />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Dealership's address</Text>
                    <Input value={campaignData.dealAddress} onChange={(e) => {setCampaignData((prev) => ({...prev!, dealAddress: e.target.value}))}} placeholder="Dealership address" />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Dealership's city</Text>
                    <Input value={campaignData.dealCity} onChange={(e) => {setCampaignData((prev) => ({...prev!, dealCity: e.target.value}))}} placeholder="Dealership city" />
                    <Flex marginTop="3%" >
                        <Text fontWeight={400} fontSize="16px">Default product/service</Text>
                        <Tooltip label="Each outbound call requires knowing the product/service in which the customer previously demonstrated interest. In the event that this information is not available, which product/service should we assume the customer is probably interested in?">
                            <QuestionIcon margin="auto" color="blue.600" marginLeft=".5%" />
                        </Tooltip>
                    </Flex>
                    <Input placeholder="Default Product or Service" value={campaignData.defaultProduct} onChange={(e) => {setCampaignData((prev) => ({...prev!, defaultProduct: e.target.value}))}} />
                    <Flex marginTop="3%" direction="column">
                       <Flex>
                           <Text fontWeight={400} fontSize="16px">Phone number</Text>
                           <Tooltip label="The phone number associated with the dealership for this campaign. Calls will be made from this phone number.">
                               <QuestionIcon margin="auto" color="blue.600" marginLeft=".5%" />
                           </Tooltip>
                       </Flex>
                       <Flex>
                            <InputGroup>
                                <InputLeftAddon>
                                    <IconButton
                                        aria-label="Copy phone number"
                                        onClick={onHandleCopyPhoneNumber}
                                        isDisabled={!Boolean(campaignData?.dealPhoneNumber)}
                                    >
                                        <Text boxSize="auto">Copy</Text>
                                    </IconButton>
                                </InputLeftAddon>
                                <Input
                                    isReadOnly
                                    value={formatPhoneNumber(campaignData?.dealPhoneNumber)}
                                    pr="4.5rem"
                                />
                            </InputGroup>
                            <Button ml={2}
                               onClick={() => generatePhoneNumberMutation.mutate()}
                               isLoading={generatePhoneNumberMutation.isPending}
                               loadingText="Generating..."
                               >
                                Generate
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex marginTop="3%">
                        <Text fontWeight={400} fontSize="16px">Forward number</Text>
                        <Tooltip label="In case a customer calls back to the phone number used in this campaign, to which number should we forward the call?">
                            <QuestionIcon margin="auto" color="blue.600" marginLeft=".5%" />
                        </Tooltip>
                    </Flex>
                    <PhoneInput
                        forceDialCode={true}
                        defaultCountry="us"
                        value={campaignData.forwardNumber}
                        onChange={(phone) => setCampaignData((prev) => ({...prev, forwardNumber: phone}))}
                        countrySelectorStyleProps={{buttonStyle: {background: 'transparent', height: '40px'}}}
                        inputStyle={{width: '100%', fontSize:"15px", background:"transparent", height: '40px'}}
                        countries={defaultCountries.filter((country) => {
                            const { iso2 } = parseCountry(country);
                            return ['us'].includes(iso2);
                          })}
                    />
                    <Flex marginTop="3%" direction="column">
                    <Flex>
                        <Text fontWeight={400} fontSize="16px">Lead Import Email</Text>
                        <Tooltip label="You can send your email leads to this email address. Upon receipt, our system will automatically import the customer details and initiate call with the customer.">
                            <QuestionIcon margin="auto" color="blue.600" marginLeft=".5%" />
                        </Tooltip>
                    </Flex>
                        <InputGroup>
                            <InputLeftAddon>
                                <IconButton
                                    aria-label="Copy email"
                                    onClick={onHandleCopyEmailLeadImport}
                                >
                                    <Text boxSize="auto">Copy</Text>
                                </IconButton>
                            </InputLeftAddon>
                            <Input
                                isReadOnly
                                value={emailLeadImport}
                                pr="4.5rem"
                            />
                        </InputGroup>
                    </Flex>
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Dealership's state</Text>
                    <Select value={campaignData.dealState} onChange={(e) => {setCampaignData((prev) => ({...prev!, dealState: e.target.value}))}} placeholder="Dealership state">
                    {ALL_STATES.map((state:{value:string,label:string}) => {
                        return <option value={state.value}>{state.label}</option>
                    })}
                    </Select>
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Dealership's zip code</Text>
                    <Input type="number" value={campaignData.dealZip} onChange={(e) => {if(e.target.value.length < 10) {setCampaignData((prev) => ({...prev!, dealZip: e.target.value}))}}} placeholder="Dealership zip" />
                    <DealershipOpeningHours
                        dealTime={campaignData?.dealTime}
                        onDealTimeChange={(newDealTime) => setCampaignData((prev) => ({...prev!, dealTime: newDealTime}))}
                    />
                    <SelectVoice selected={campaignData?.repVoice} onSelect={voice => setCampaignData(prev => ({...prev!, repVoice: voice}))} />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Representative's capabilities</Text>
                    <Select value={campaignData.model} onChange={(e) => {setCampaignData((prev) => ({...prev!, model: e.target.value}))}}>
                        <option value="standard">Standard (low latency)</option>
                        <option value="turbo">Turbo (higher latency)</option>
                    </Select>
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Daily Call Limit</Text>
                    <Input value={campaignData.dailyCallLimit} onChange={(e) => {setCampaignData((prev) => ({...prev!, dailyCallLimit: e.target.value.replace(/\D/g, '')}))}} placeholder="No Limit" />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Email the representative will send the scheduled appointments to</Text>
                    <Input value={campaignData.emailAppointment} onChange={(e) => {setCampaignData((prev) => ({...prev!, emailAppointment: e.target.value}))}} placeholder="Appointment email" />
                    <AutoDialerSchedule 
                        autoDialerSchedule={campaignData.autoDialerSchedule}
                        setAutoDialerSchedule={
                        (newAutoDialerSchedule) => {
                            setCampaignData((prev) => ({...prev!, autoDialerSchedule: newAutoDialerSchedule}))
                        }} 
                    />
                    <Text marginTop='5%' fontSize={25} fontWeight={200}>Representative's Objectives</Text>
                    <Divider />
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Overall objectives during call</Text>
                    <Wrap spacing="15px">
                        {possibleObjectives.map((obj) => {
                            return <WrapItem><Tag colorScheme={campaignData.repObjectives.map((x) => x.emoji).includes(obj.emoji) ? "green" : "gray"} onClick={() => {addObjective(obj);}} cursor="pointer" borderRadius="20px" size="lg">{obj.emoji + ' ' + obj.name}</Tag></WrapItem>
                        })}
                    </Wrap>
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Main objective</Text>
                    <Wrap spacing="12px">
                        {campaignData.repObjectives.map((obj) => {
                            return <WrapItem><Tag colorScheme={obj.emoji === campaignData.repMainGoal.emoji ? "blue" : "gray"} onClick={() => {setCampaignData((prev) => ({...prev!, repMainGoal: obj}))}} cursor="pointer" borderRadius="20px" size="lg">{obj.emoji + ' ' + obj.name}</Tag></WrapItem>
                        })}
                    </Wrap>
                    <Text marginTop="3%" fontWeight={400} fontSize="16px">Suggested questions</Text>
                    {campaignData.repObjectives.map((obj, idxObj) => {
                        return (
                            <Box marginTop="1%">
                                <Flex>
                                    <Text marginTop="auto" marginBottom="auto">{obj.emoji + ' ' + obj.name}</Text>
                                    <IconButton onClick={() => {addRepQuestion(idxObj)}} marginLeft="1%" marginTop="auto" marginBottom="auto" size="xs" variant="outline" borderRadius="20px" aria-label="Add question" icon={<AddIcon color="gray.500" />} />
                                </Flex>
                                {obj.questions && obj.questions.map((q, idxQ) => {
                                    return <Flex marginTop="1%" marginLeft="3%">
                                                <Input borderColor="#c7c7c7" borderRadius="20px" value={q} onChange={(e) => {editQuestion(idxObj, idxQ, e.target.value)}} />
                                                <IconButton onClick={() => {deleteQuestion(idxObj, idxQ)}} colorScheme="gray" marginTop="auto" marginBottom="auto" marginLeft='1%' borderRadius="20px" aria-label="Delete field" icon={<DeleteIcon />} size='sm' variant='outline' />
                                            </Flex>
                                })}
                            </Box>
                        )
                    })}
                    <Flex marginTop='5%'>
                        <Text  fontSize={25} fontWeight={200}>Knowledge Base</Text>
                        <InputGroup marginLeft="1%" marginTop="auto" marginBottom="auto" size="xs" w="80%">
                            <InputLeftElement pointerEvents='none'>
                                <SearchIcon color='gray.300' />
                            </InputLeftElement>
                            <Input
                            value={searchInstruction}
                            onChange={(e) => {
                            setSearchInstruction(e.target.value)
                            }}
                            borderRadius="20px"
                            placeholder='Search'
                            />
                        </InputGroup>
                    </Flex>
                    <Divider />
                    <InstructionsComponent campaignData={campaignData} setCampaignData={setCampaignData} searchInstruction={searchInstruction} />
                    <Center>
                    <Button
                        marginTop="2%"
                        leftIcon={<AddIcon />}
                        variant="outline"
                        borderRadius="20px"
                        onClick={() => {setCampaignData((prev) => {
                            if (prev) {
                                return {
                                    ...prev,
                                    repInstructions: prev.repInstructions ? [...prev.repInstructions, {question: '', answer: ''}] : [{question: '', answer: ''}]
                                };
                            }
                            return prev;
                        })
                        }}
                        size="sm"
                        >Add instruction</Button>
                    </Center>
                    {/*
                    ADD INVENTORY COMPONENT IN THE FUTURE
                    
                    <Flex marginTop='5%'>
                        <Text  fontSize={25} fontWeight={200}>Inventory</Text>
                        <InputGroup marginLeft="1%" marginTop="auto" marginBottom="auto" size="xs" w="80%">
                            <InputLeftElement pointerEvents='none'>
                                <SearchIcon color='gray.300' />
                            </InputLeftElement>
                            <Input
                            value={searchProduct}
                            onChange={(e) => {
                                setSearchProduct(e.target.value)
                            }}
                            borderRadius="20px"
                            placeholder='Search'
                            />
                        </InputGroup>
                    </Flex>
                    <Divider />
                    <InventoryComponents campaignData={campaignData} setCampaignData={setCampaignData} searchProduct={searchProduct} />
                    <Center>
                        <Button
                            marginTop="1%"
                            leftIcon={<AddIcon />}
                            variant="outline"
                            borderRadius="20px"
                            onClick={() => {setCampaignData((prev) => {
                                if (prev) {
                                    return {
                                        ...prev,
                                        repInstructions: prev.repInstructions ? [...prev.repInstructions, {question: '', answer: ''}] : [{question: '', answer: ''}]
                                    };
                                }
                                return prev;
                            })
                            }}
                            size="sm"
                            >Add product</Button>
                    </Center>
                    */}
                </Box>
            </Center>
            <Center marginTop="4%">
                <Button onClick={() => {setDeleteCampaignWindow(true)}} variant="outline" colorScheme="red">Delete campaign</Button>
                <AlertDialog
                isOpen={deleteCampaignWindow}
                onClose={() => {setDeleteCampaignWindow(false)}}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Delete Campaign
                    </AlertDialogHeader>
                    <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                    <Button onClick={() => {setDeleteCampaignWindow(false)}}>
                        Cancel
                    </Button>
                    <Button colorScheme='red' onClick={() => {deleteCampaign();}} ml={3}>
                        Delete
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            </Center>
        </Box>
    )
}