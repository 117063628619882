import { Box, Button, Center, Flex, IconButton, Spacer, Table, Tbody, Td, Th, Thead, Tr, Text, Input, InputGroup, InputLeftElement, Tooltip, Divider, Badge, VStack, Modal, ModalContent, ModalCloseButton, ModalBody, ModalOverlay, ModalHeader, Avatar, useToast, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { DatePicker } from "../components/DatePicker";
import CSVUploader from "../components/csvUploader";
import { PhoneIcon, AtSignIcon, DeleteIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon, InfoIcon, StarIcon, ArrowBackIcon, EditIcon, EmailIcon, DownloadIcon, ChevronDownIcon, Icon } from "@chakra-ui/icons";
import {useEffect, useState, useRef, useCallback} from 'react'
import { ref as refStorage, getDownloadURL } from 'firebase/storage';
import { firestoreDb, storage } from "../firebase";
import { CustomerInterface, CampaignDataInterface } from "../utils/interfaces";
import fetchCallCustomer from "../helpers/fetchCallCustomer";
import fetchTerminateCall from "../helpers/fetchTerminateCall";
import { BsPlay } from "react-icons/bs";
import { collection, doc, onSnapshot, orderBy, query, updateDoc, where, getDocs, limit, startAfter, startAt, endAt, deleteDoc } from "firebase/firestore";
import { FaCircle } from "react-icons/fa";
import { capitalize } from '../utils/others';



const pageSize = 20;

export default function CustomersPage({campaignData, campaignId}: {campaignData?: CampaignDataInterface, campaignId:string}){
    const [searchCustomer, setSearchCustomer] = useState<string>('')
    const [allCustomers, setAllCustomers] = useState<CustomerInterface[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [validCustomers, setValidCustomers] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [rowHover, setRowHover] = useState<number>(-1);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerInterface>();
    const [openCallInfo, setOpenCallInfo] = useState<string>("");
    const [currentCall, setCurrentCall] = useState<string>('');
    const [realTimeConv, setRealTimeConv] = useState<any>([]);
    const [allCustomerCalls, setAllCustomerCalls] = useState<any>();
    const [loadingCall, setLoadingCall] = useState<boolean>(false);
    const [callAudioUrl, setCallAudioUrl] = useState<string | null>(null);
    const toast = useToast();
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [selectedDate, setSelectedDate] = useState<null | Date>(null);

    const downloadCsv = useCallback((dataToExport: any[], filename: string) => {
        const csvRows = [
            ['Name', 'Phone', 'Email', 'Product', 'Address', 'Audience', 'Attempts', 'Last Attempt'],
            ...dataToExport.map((customer) => [
                customer.name || '',
                customer.phone_number || '',
                customer.email || '',
                customer.product || '',
                customer.address || '',
                customer.audience || '',
                customer.calls ? `${customer.calls[customer.calls.length -1].answered ? '1' : 0}/${customer.calls.length}` : "",
                customer.calls ? new Date(parseInt(customer.calls[customer.calls.length - 1].createdAt)*1000).toLocaleDateString() : "",
            ])
        ].map(e => e.join(',')).join('\n');
    
        const blob = new Blob([csvRows], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }, []);

    useEffect(() => {
        const fetchFilteredCustomers = async () => {
            if (selectedDate) {
                const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
                const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
                const callsRef = collection(firestoreDb, `campaigns/${campaignId}/calls`);
                const q = query(
                    callsRef,
                    where('startCall', '>=', startOfDay),
                    where('startCall', '<=', endOfDay)
                );
                const callDocs = await getDocs(q);
                const customerIdsWithCalls = new Set(callDocs.docs.map(doc => doc.data().customerId));
                const filteredCustomers = allCustomers.filter(customer => customerIdsWithCalls.has(customer.id));
                setValidCustomers(filteredCustomers);
                setTotalPages(Math.ceil(filteredCustomers.length / pageSize));
            } else {
                setValidCustomers(allCustomers);
                setTotalPages(Math.ceil(allCustomers.length / pageSize));
            }
        };
        fetchFilteredCustomers().catch(console.error);
    }, [selectedDate, allCustomers, campaignId]);
    
    const handleExportAll = useCallback(() => {
        downloadCsv(validCustomers, `${campaignData?.campaignName}-customers-all.csv`);
    }, [validCustomers, campaignData?.campaignName, downloadCsv]);
    
    const handleExportUnanswered = useCallback(() => {
        const unansweredCustomers = validCustomers.filter(customer => 
            !customer.calls || customer.calls.length === 0 || !customer.calls[customer.calls.length - 1]?.answered
        );
        if (unansweredCustomers.length === 0) {
            toast({
                title: "No unanswered customers",
                description: "No unanswered customers found",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        downloadCsv(unansweredCustomers, `${campaignData?.campaignName}-customers-unanswered.csv`);
    }, [validCustomers, campaignData?.campaignName, downloadCsv]);

    const handleExportAnswered = useCallback(() => {
        const answeredCustomers = validCustomers.filter(customer => 
            customer.calls && customer.calls[customer.calls.length - 1]?.answered
        );
        if (answeredCustomers.length === 0) {
            toast({
                title: "No answered customers",
                description: "No answered customers found",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        downloadCsv(answeredCustomers, `${campaignData?.campaignName}-customers-answered.csv`);
    }, [validCustomers, campaignData?.campaignName, downloadCsv]);
    
    useEffect(() => {
        const customersRef = collection(firestoreDb, 'campaigns', campaignId, 'customers');
        const q = query(customersRef, orderBy('name'), startAfter(currentPage * pageSize),  startAt(capitalize(searchCustomer)), endAt(capitalize(searchCustomer) + '\uf8ff'), limit(pageSize));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const customers:any = [];
            snapshot.forEach((doc) => {
                customers.push({id: doc.id, ...doc.data()});
            });
            setAllCustomers(customers);
            setTotalPages(Math.ceil(customers.length / pageSize));
        }, error => {
            console.error('Error fetching customers:', error);
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (selectedDate) {
            const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)).getTime() / 1000; // Convert to seconds
            const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)).getTime() / 1000; // Convert to seconds
            const filteredCustomers = allCustomers.filter((customer) =>
                customer.calls && customer.calls.some((call) =>
                    call.createdAt >= startOfDay && call.createdAt <= endOfDay
                )
            );
            setValidCustomers(filteredCustomers);
            setTotalPages(Math.ceil(filteredCustomers.length / pageSize));
        } else {
            setValidCustomers(allCustomers);
            setTotalPages(Math.ceil(allCustomers.length / pageSize));
        }
    }, [selectedDate, allCustomers]);

    useEffect(() => {
        //Only get calls where toNumber === selectedCustomer.phone_number
        if (selectedCustomer) {
            const callsRef = collection(firestoreDb, 'campaigns', campaignId, 'calls');
            const q = query(callsRef, where('toNumber', '==', selectedCustomer.phone_number), orderBy('startCall', 'desc'));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const calls:any = {};
                snapshot.forEach((doc) => {
                    calls[doc.id] = doc.data();
                });
                setAllCustomerCalls(calls);
            });
            return () => unsubscribe();
            }
    }, [selectedCustomer])

    function formatPhoneNumber(number:number) {
        if (number){
            number = number.toString();
            const intCode = number.substring(0, 2);
            const areaCode = number.substring(2, 5);
            const first3Digits = number.substring(5, 8);
            const last4Digits = number.substring(8);        
            return `${intCode} (${areaCode}) ${first3Digits}-${last4Digits}`;
        }
      }

      async function deleteCustomer() {
        if (!selectedCustomer) {
            return;
        }
        const customerDocRef = doc(firestoreDb, 'campaigns', campaignId, 'customers', selectedCustomer.id);
        await deleteDoc(customerDocRef);
        setSelectedCustomer(undefined);
        toast({
            title: "Customer deleted",
            description: "Customer deleted successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
        })
    }

    useEffect(() => {
        if (allCustomers) {
            const validCustomers = allCustomers
                .map((customer, index) => ({ ...customer, index }))
                .filter((customer) => {
                    const customerValues = Object.values(customer).map((value) =>
                        typeof value === "string" ? value.toLowerCase() : ""
                    );
                    return customerValues.some((value) =>
                        value.includes(searchCustomer.toLowerCase())
                    );
                });
            setValidCustomers(validCustomers);
            setTotalPages(Math.ceil(validCustomers.length / pageSize));
        }
    }, [searchCustomer, allCustomers]);

    useEffect(() => {
        if (currentCall) {
            setRealTimeConv(allCustomerCalls[currentCall])
            if (allCustomerCalls[currentCall].endCall) {
                setCurrentCall('');
            }
        }

    }, [allCustomerCalls, currentCall])


    useEffect(() => {
        if (openCallInfo.length > 0) {
            const audioRef = refStorage(storage, `recordings/${openCallInfo}.mp3`)
            getDownloadURL(audioRef)
            .then((url) => {
                setCallAudioUrl(url);
            })
            .catch((error) => {
                setCallAudioUrl(null);
            });
        }
    }, [openCallInfo])

    function updateCampaignStatus() {
        if (allCustomers.length === 0) {
            toast({
                title: "No customers",
                description: "You need to upload customers before activating the campaign",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        if (campaignData) {
            const campaignRef = doc(firestoreDb, "campaigns", campaignId);

            updateDoc(campaignRef, {
                active: !campaignData.active,
            })
                .then(() => {
                    toast({
                        title: campaignData.active ? "Campaign stopped" : "Campaign started",
                        description: campaignData.active
                            ? "Your campaign has been stopped and calls will pause"
                            : "Your campaign has been started and calls will start soon",
                        status: campaignData.active ? "warning" : "success",
                        duration: 5000,
                        isClosable: true,
                    });
                })
                .catch((error) => {
                    console.error("Error updating campaign status:", error);
                    toast({
                        title: "Error",
                        description: "Failed to update campaign status",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                });
        }
    }

    function callCustomer(){
        if (selectedCustomer) {
            setLoadingCall(true);
            fetchCallCustomer(campaignId, selectedCustomer, toast).then((callKey) => {
                setLoadingCall(false)
                if (callKey) {
                    setCurrentCall(callKey);
                }
            })
        }
    }

    function pickLabelCircle(calls) {
        if (calls) {
            const lastCall = calls[calls.length - 1];
            if (lastCall.answered) {
                return "Call successful at " + new Date(parseInt(lastCall.createdAt)*1000).toLocaleTimeString() + " on " + new Date(parseInt(lastCall.createdAt)*1000).toLocaleDateString();
            } else {
                return `Attempt #${calls.length} of trying to reach customer failed at ` + new Date(parseInt(lastCall.createdAt)*1000).toLocaleTimeString() + " on " + new Date(parseInt(lastCall.createdAt)*1000).toLocaleDateString();
            }
        }
        return "No calls yet";
    }

    function pickCircleColor(calls) {
        if (calls) {
            const lastCall = calls[calls.length - 1];
            if (lastCall.answered) {
                return "green.500";
            } else {
                return `red.${calls.length * 200}`
            }
        }
        return "gray.500";
    }

    return (
        <Box padding="5%" paddingTop="2%" overflowY="auto" bg="#fbfafb" w="85%" h="100vh">
            <Flex alignItems="center" mb="20px">
                <Flex>
                    <Text fontSize={35} fontWeight={100}>Customers</Text>
                    <Tooltip label = {campaignData.active ? "Campaign is active" : "Campaign is not active"}>
                        <IconButton
                            colorScheme={campaignData.active ? 'green' : "orange"}
                            variant="outline"
                            aria-label="Play Campaign"
                            icon={<BsPlay fontSize="25px" />}
                            onClick={() => {updateCampaignStatus()}}
                            size="md"
                            borderRadius="30px"
                            marginTop="auto"
                            marginBottom="auto"
                            marginLeft="3%"
                        />
                    </Tooltip>
                </Flex>
                <Spacer />
                <CSVUploader campaignData={campaignData} campaignId={campaignId} allCustomers={allCustomers} />
                {validCustomers.length > 0 && (
                    <Menu>
                        <MenuButton as={Button} leftIcon={<DownloadIcon />} size="sm" variant="outline" colorScheme="blue" ml={2}>
                            Export Customers <ChevronDownIcon />
                        </MenuButton>
                        <MenuList >
                            <MenuItem onClick={() => handleExportAll()}>All</MenuItem>
                            <MenuItem onClick={() => handleExportUnanswered()}>Unanswered</MenuItem>
                            <MenuItem onClick={() => handleExportAnswered()}>Answered</MenuItem>
                        </MenuList>
                    </Menu>
                )}
            </Flex>
            {!selectedCustomer ? (
            <Box padding="20px" w="100%" boxShadow="lg" border="1px solid #e8e8e8" borderRadius="10px">
                <Flex mb="20px">
                    <InputGroup marginTop="auto" marginBottom="auto" minW="200px" maxW="300px" mr="10px" size="sm">
                        <InputLeftElement>
                            <SearchIcon color='gray.300' />
                        </InputLeftElement>
                        <Input
                        value={searchCustomer}
                        onChange={(e) => {
                        setSearchCustomer(e.target.value)
                        }}
                        borderRadius="20px"
                        placeholder='Search'
                        bg="white"
                        />
                    </InputGroup>
                    <DatePicker
                        onChange={setSelectedDate}
                        placeholderText='Select Date'
                        selected={selectedDate}
                        isClearable
                        maxDate={new Date()}
                        inputProps={{
                            size: "sm",
                            minW: "200px"
                        }}
                    />
                </Flex>
                <Table size="sm" variant="simple">
                    <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Phone</Th>
                        <Th>Email</Th>
                        <Th>Product</Th>
                        <Th>Address</Th>
                        <Th>Audience</Th>
                        <Th>Attempt</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {validCustomers.slice(pageSize*(currentPage -1) , pageSize*currentPage).map((contact:any, idx:number) => (
                        <Tr key={idx} onClick={() => {setSelectedCustomer(contact)}} cursor="pointer" onMouseEnter={() => {setRowHover(idx)}} onMouseLeave={() => {setRowHover(-1)}} bg={rowHover === idx ? "gray.100" : ''}>
                            <Td>
                                <Tooltip label={contact.name}>
                                    <Text noOfLines={1}>{contact.name}</Text>
                                </Tooltip>
                            </Td>
                            <Td>
                                <Flex>
                                    <PhoneIcon color="gray.600" marginTop="auto" marginBottom="auto" mr={2} />
                                    <Tooltip label={formatPhoneNumber(contact.phone_number)}>
                                        <Text noOfLines={1}>{formatPhoneNumber(contact.phone_number)}</Text>
                                    </Tooltip>
                                </Flex>
                            </Td>
                            <Td>
                                <Flex>
                                    <AtSignIcon color="gray.600" marginTop="auto" marginBottom="auto" mr={2} />
                                    <Tooltip label={contact.email}>
                                        <Text noOfLines={1}>{contact.email}</Text>
                                    </Tooltip>
                                </Flex>
                            </Td>
                            <Td>
                                <Flex>
                                    <StarIcon color="gray.600" marginTop="auto" marginBottom="auto" mr={2} />
                                    <Tooltip label={contact.product}>
                                        <Text noOfLines={1}>{contact.product}</Text>
                                    </Tooltip>
                                </Flex>
                            </Td>
                            <Td>
                                <Flex>
                                    <Tooltip label={contact.address}>
                                        <Text noOfLines={1}>{contact.address}</Text>
                                    </Tooltip>
                                </Flex>
                            </Td>
                            <Td>
                                <Tooltip label={contact.audience}>
                                    <Text noOfLines={1}>{contact.audience}</Text>
                                </Tooltip>
                            </Td>
                            <Td>
                                <Center>
                                    <Tooltip label={pickLabelCircle(contact.calls)} >
                                        <Box>
                                            <Icon as={FaCircle} color={pickCircleColor(contact.calls)} />
                                        </Box>
                                    </Tooltip>
                                </Center>
                            </Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
                <Flex justify="space-between" align="center" mt={4}>
                    <Button
                    onClick={() => {setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))}}
                    disabled={currentPage === 1}
                    leftIcon={<ChevronLeftIcon />}
                    variant="outline"
                    >
                    Previous
                    </Button>
                    <Text>
                    Page {currentPage} of {totalPages}
                    </Text>
                    <Button
                    variant="outline"
                    onClick={() => {setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))}}
                    disabled={currentPage === totalPages}
                    rightIcon={<ChevronRightIcon />}
                    >
                    Next
                    </Button>
                </Flex>
                </Box>
                )
                :
                (<Box
                    padding="3%"
                    w="100%"
                    boxShadow="lg"
                    border="1px solid #e8e8e8"
                    borderRadius="10px"
                    bg="white"
                  >
                    <Flex>
                      <IconButton
                        aria-label="Back"
                        icon={<ArrowBackIcon />}
                        variant="ghost"
                        onClick={() => setSelectedCustomer(undefined)}
                      />
                      <Text
                        fontWeight="600"
                        color="gray.700"
                        fontSize="2xl"
                      >
                        {selectedCustomer.name}
                      </Text>
                    </Flex>
                    <Divider my={4} />
                    <Text fontSize="20px" fontWeight={700} color="gray.400" marginBottom="1%">Customer Information</Text>
                    <VStack spacing={4} align="stretch">
                      <Text
                        fontSize="lg"
                        fontWeight="500"
                        bg="blue.50"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                      >
                        <EmailIcon mr={2} color="blue.500" />
                        {selectedCustomer.email}
                      </Text>
                      <Text
                        fontSize="lg"
                        fontWeight="500"
                        bg="blue.50"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                      >
                        <PhoneIcon mr={2} color="blue.500" />
                        {selectedCustomer.phone_number}
                      </Text>
                      <Text
                        fontSize="lg"
                        fontWeight="500"
                        bg="blue.50"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                      >
                        <InfoIcon mr={2} color="blue.500" />
                        {selectedCustomer.address}
                      </Text>
                      <Text
                        fontSize="lg"
                        fontWeight="500"
                        bg="blue.50"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                      >
                        <EditIcon mr={2} color="blue.500" />
                        {selectedCustomer.product}
                      </Text>
                      <Text
                        fontSize="lg"
                        fontWeight="500"
                        bg="blue.50"
                        borderRadius="10px"
                        p={2}
                        display="flex"
                        alignItems="center"
                      >
                        <StarIcon mr={2} color="blue.500" />
                        {selectedCustomer.audience}
                      </Text>
                    </VStack>
                    <Divider marginTop="3%" marginBottom="2%"/>
                    <Text fontSize="20px" fontWeight={700} color="gray.400" marginBottom="1%">Previous Calls</Text>
                    <Box
                      padding="3%"
                      w="100%"
                      boxShadow="lg"
                      border="1px solid #e8e8e8"
                      borderRadius="10px"
                      bg="white"
                      h="350px"
                      overflowY="scroll"
                    >
                        <Table >
                            <Thead>
                                <Tr>
                                    <Th>Answer</Th>
                                    <Th>Date</Th>
                                    <Th>Time</Th>
                                    <Th>Length</Th>
                                    <Th>Goal Accomplished</Th>
                                </Tr>
                            </Thead>
                            <Tbody h="200px" >
                                {allCustomerCalls && Object.keys(allCustomerCalls)
                                    .sort((a, b) => allCustomerCalls[b].startCall - allCustomerCalls[a].startCall)
                                    .map((callIdx, idx) => (
                                        <Tr onClick={() => {setOpenCallInfo(callIdx)}} bg={rowHover === idx ? 'gray.100' : "white"} cursor="pointer" onMouseEnter={(() => {setRowHover(idx)})} onMouseLeave={() => {setRowHover(-1)}}>
                                        <Td><PhoneIcon color={allCustomerCalls[callIdx].dialogue ? "green.500" : 'red.500'} /></Td>
                                        <Td>{new Date(parseInt(allCustomerCalls[callIdx].startCall)*1000).toLocaleDateString()}</Td>
                                        <Td>{new Date(parseInt(allCustomerCalls[callIdx].startCall)*1000).toLocaleTimeString()}</Td>
                                        <Td>{!allCustomerCalls[callIdx].dialogue ? '' : allCustomerCalls[callIdx].endCall - allCustomerCalls[callIdx].startCall  < 60 ? `${parseInt(allCustomerCalls[callIdx].endCall - allCustomerCalls[callIdx].startCall)} seconds` : `${Math.floor((allCustomerCalls[callIdx].endCall - allCustomerCalls[callIdx].startCall)/60)} minute(s)`}</Td>
                                        <Td>{allCustomerCalls[callIdx].evaluation ? allCustomerCalls[callIdx].evaluation.objectiveAccomplished : "Under Analysis"}</Td>
                                    </Tr>
                                    ))}
                                                                        
                            </Tbody>
                        </Table>
                        <Modal size="lg" isOpen={openCallInfo.length > 0} onClose={() => {setOpenCallInfo("")}}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalCloseButton />
                                <ModalHeader>Call Info</ModalHeader>
                                <ModalBody padding="5%">
                                    <Center>
                                        <audio controls src={callAudioUrl}>
                                            <a href={callAudioUrl}> Download audio </a>
                                        </audio>
                                    </Center>
                                    <Divider marginTop="3%" />
                                    {allCustomerCalls && allCustomerCalls[openCallInfo] && allCustomerCalls[openCallInfo].dialogue && (
                                    <Box overflowY="auto" h="43vh" padding="2%" ref={messagesEndRef}>
                                        {allCustomerCalls[openCallInfo].dialogue.map((message, index) => {
                                            if (message.role !== 'system') {
                                                return (
                                                    <Flex marginTop="2%" key={index} justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}>
                                                        {message.role === 'assistant' && <Avatar name={message.role === 'user' ? selectedCustomer.name : campaignData.repName} size="sm" marginRight={message.role === 'user' ? '0' : '2'} marginLeft={message.role === 'user' ? '2' : '0'} />}
                                                        <Box bg={message.role === 'assistant' ? "#f1f8ff" : "white"} boxShadow="lg" border={"2px solid #c0dbf6"} borderRadius="10px" padding="2%" maxWidth="70%">
                                                            <Text fontSize={13} fontWeight={600}>{message.role === 'user' ? selectedCustomer.name : campaignData.repName}</Text>
                                                            <Text color="gray.600" fontSize={12}>{message.content}</Text>
                                                        </Box>
                                                        {message.role === 'user' && <Avatar name={selectedCustomer.name} size="sm" marginLeft={2} />}
                                                    </Flex>
                                                )}
                                                return
                                        })}
                                    </Box>)}
                                    <Text textAlign="center" marginTop="3%" fontWeight={500} color="gray.600" fontSize="12px" bg="blue.100" padding="2%" borderRadius="10px" border="1px solid #90cdf4">{openCallInfo.length > 0 && allCustomerCalls[openCallInfo].evaluation ? allCustomerCalls[openCallInfo].evaluation.summary : "Under Analysis..."}</Text>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </Box>
                    <Divider marginTop="2%" marginBottom="2%" />
                    <Text fontSize="20px" fontWeight={700} color="gray.400" marginBottom="1%">Actions</Text>
                    {currentCall.length === 0 ? 
                    <Center>
                        <Button isLoading={loadingCall} onClick={() => {callCustomer();}} colorScheme="green" leftIcon={<PhoneIcon />} variant="outline">Call Customer</Button>
                        <Button onClick={() => {deleteCustomer()}} marginLeft="5%" colorScheme="red" variant="outline" leftIcon={<DeleteIcon/>}>Delete Customer</Button>
                    </Center>
                    :
                    <Center>
                        <Box w="80%">
                            <Box bg="white" boxShadow="lg" marginTop="2%" h="50vh" w="100%" border="1.2px solid #dfebf6" borderRadius="15px">
                                <Flex h="7vh">
                                    <Text padding="1%" fontSize="22px" color="gray.600" fontWeight={500}>Real Time Transcription</Text>
                                    <Spacer />
                                    {realTimeConv.streaming ? <Badge colorScheme="green" variant="outline" padding=".7%" borderRadius="20px" margin="auto" fontWeight={500} marginRight="2%">Connected</Badge> : <Badge colorScheme="blue" variant="outline" borderRadius="20px" margin="auto" padding=".7%" fontWeight={500} marginRight="2%">Connecting...</Badge>}
                                </Flex>
                                <Divider />
                                {realTimeConv.dialogue && realTimeConv.dialogue.length > 0 && (
                                <Box overflowY="auto" h="43vh" padding="2%" ref={messagesEndRef}>
                                    {realTimeConv.dialogue.map((message, index) => {
                                        if (message.role !== 'system') {
                                            return (
                                                <Flex marginTop="2%" key={index} justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}>
                                                    {message.role === 'assistant' && <Avatar name={message.role === 'user' ? selectedCustomer.name : campaignData.repName} size="sm" marginRight={message.role === 'user' ? '0' : '2'} marginLeft={message.role === 'user' ? '2' : '0'} />}
                                                    <Box bg={message.role === 'assistant' ? "#f1f8ff" : "white"} boxShadow="lg" border={"2px solid #c0dbf6"} borderRadius="10px" padding="2%" maxWidth="70%">
                                                        <Text fontSize={13} fontWeight={600}>{message.role === 'user' ? selectedCustomer.name : campaignData.repName}</Text>
                                                        <Text color="gray.600" fontSize={12}>{message.content}</Text>
                                                    </Box>
                                                    {message.role === 'user' && <Avatar name={selectedCustomer.name} size="sm" marginLeft={2} />}
                                                </Flex>
                                            )}
                                            return
                                    })}
                                </Box>)}
                            </Box>
                            <Center marginTop="2%">
                                <Button onClick={() => {fetchTerminateCall(currentCall, campaignId)}} variant="outline" colorScheme="red" leftIcon={<PhoneIcon />}>End Call</Button>
                            </Center>
                        </Box>
                    </Center>
                    }
                  </Box>
                  )}                
            </Box>
    )
}
