import { AddIcon, ArrowBackIcon, ArrowForwardIcon, CheckIcon, DeleteIcon, QuestionIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Spacer,
    Tag,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    Flex,
    IconButton,
    ModalHeader,
    Stepper,
    Step,
    StepIndicator,
    StepTitle,
    StepSeparator,
    StepDescription,
    StepStatus,
    StepIcon,
    StepNumber,
    useSteps,
    ModalBody,
    Input,
    Select,
    ModalFooter,
    useToast,
    Wrap,
    WrapItem,
    Divider,
    SlideFade,
    Textarea,
    Center,
    Tooltip,
    FormControl,
    InputGroup,
    InputLeftAddon,
    FormLabel,
} from '@chakra-ui/react'
import { auth, firestoreDb } from '../firebase';
import {useEffect, useState} from 'react';
import { allTimezones, newProductPlaceholder, steps, ALL_STATES, possibleObjectives } from '../utils/others';
import { addDoc, collection, doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { ProjectWindowInterface, ObjectiveInterface, InstructionInterface, CampaignDataInterface } from '../utils/interfaces';
import fetchCheckDealershipName from '../helpers/fetchCheckDealershipName';
import AutoDailerSchedule, { AutoDialerScheduleType } from './autoDialerSchedule';
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import DealershipOpeningHours from './DealershipOpeningHours';
import SelectVoice from './selectRepresentativeVoice';


const defaultInstructions = [
    {question: "Can I do a Test Drive at home?", answer: "Unfortunately, we're not able to offer at-home test drives at this time. If you'd like, I can have a member of the sales team get in touch to review all other possible options."},
    {question: "Do you have video walk-around?", answer: "We can definitely get a video walkaround for you! I'll have your sales associate reach out."},
    {question: "How much is a deposit?", answer: "Deposits will vary depending on a wide range of factors, such as the vehicle type and price. If you're interested in placing a deposit, one of our experts will contact you to discuss further."},
    {question: "How much is the down payment?", answer: "The down payment amount can vary based on a number of factors, such as your desired financing plan and payment schedule. One of our financing experts will work with you to find a solution that fits within your budget."},
    {question: "How much is the monthly payment?", answer: "The monthly payment will depend on multiple factors, such as the final price of the vehicle, down payment, trade-in value, financing terms and interest rate. A member of our team will help customize a finance package that works for you."},
    {question: "What is the cost to ship this vehicle?", answer: "The cost of shipping can vary quite a bit depending on where you're located. We'd be happy to have a member of our team reach out to help make sure that you get the fastest delivery and lowest possible price available."},
    {question: "How much are the fees?", answer: "The taxes can vary quite a bit from state to state and even city to city. If you'd like, I can have a sales representative give you a quick call to go over the details."}
]

export default function NewProjectWindow({openNewProject, setOpenNewProject}:ProjectWindowInterface){
    const [campaignName, setCampaignName] = useState<string>('');
    const [repName, setRepName] = useState<string>('');
    const [dealName, setDealName] = useState<string>('');
    const [dealAddress, setDealAddress] = useState<string>('');
    const [dealTime, setDealTime] = useState<CampaignDataInterface['dealTime']>({});
    const [repVoice, setRepVoice] = useState<string>('');
    const [repObjectives, setRepObjectives] = useState<ObjectiveInterface[]>([]);
    const [repMainGoal, setRepMainGoal] = useState<ObjectiveInterface>();
    const [objSubSteps, setObjSubSteps] = useState<number>(0);
    const [repInstructions, setRepInstructions] = useState<InstructionInterface[]>(defaultInstructions);
    const [repProducts, setRepProducts] = useState<string[]>([]);
    const [dealCity, setDealCity] = useState<string>('');
    const [dealState, setDealState] = useState<string>('');
    const [dealZip, setDealZip] = useState<string>('');
    const [autoDialerSchedule, setAutoDialerSchedule] = useState<AutoDialerScheduleType>({});
    const [defaultProduct, setDefaultProduct] = useState<string>('');
    const [repManager, setRepManager] = useState<string>('');
    const [dealershipOptions, setDealershipOptions] = useState<[]>([]);
    const [addNewDealership, setAddNewDealership] = useState<boolean>(false);
    const [newDealership, setNewDealership] = useState<string>('');
    const [forwardNumber, setForwardNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
      })

      useEffect(() => {
        let unsubscribe: Function | undefined;
        const fetchData = async () => {
            if (!auth.currentUser) {
                console.error('No user signed in');
                return;
            }
            const userRef = doc(firestoreDb, 'users', auth.currentUser.uid);
            const userDoc = await getDoc(userRef);
            if (!userDoc.exists()) {
                await setDoc(userRef, { dealershipOptions: [] });
            }

            unsubscribe = onSnapshot(userRef, (user) => {
                const userData = user.data();
                if (userData) {
                    setDealershipOptions(userData.dealershipOptions ? userData.dealershipOptions : []);
                }
            });
        };
        fetchData();
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, []);

    function checkNext(){
        function showToast(title:string, description:string) {
            return toast({
                title: title,
                description: description,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    
        if (activeStep === 0) {
            const checks:{value:string|boolean, desc:string, msg:string}[] = [
                { value: campaignName, msg: 'No campaign name provided', desc: 'Pick a name for your campaign' },
                { value: repName, msg: 'No representative name provided', desc: 'Pick a name for your representative' },
                { value: dealName, msg: 'No dealership name provided', desc: 'Pick a name for your dealership' },
                { value: repManager, msg: 'No representative manager provided', desc: 'Pick a name for your representative manager'},
                { value: dealAddress, msg: 'No dealership address provided', desc: 'Pick a location for your dealership' },
                { value: dealCity, msg: 'No dealership city provided', desc: 'Pick a city for your dealership' },
                { value: dealState, msg: 'No dealership state provided', desc: 'Pick a state for your dealership' },
                { value: dealZip, msg: 'No dealership zipcode provided', desc: 'Pick a zipcode for your dealership' },
                { value: defaultProduct, msg: 'No default product/service provided', desc: 'Choose a default product/service for your dealership' },
                { value: !Object.keys(dealTime).includes('timezone'), msg: 'No dealership timezone provided', desc: 'Pick a timezone for operating hours'},
                { value: !Object.keys(dealTime).includes('from') || !Object.keys(dealTime).includes('to')  || dealTime.from?.length === 0 || dealTime.to?.length === 0, msg: 'No dealership operating hours provided', desc: 'Pick operating hours for your dealership' },
                { value: repVoice.length === 0, msg: 'No voice selected', desc: 'Pick a voice for your representative' },
            ];
    
            for (const check of checks) {
                if ((typeof check.value === 'string' && check.value.length === 0) || (typeof check.value === 'boolean' && check.value)) {
                    return showToast(check.msg, check.desc);
                }
            }
        }
    
        if (activeStep === 1 && objSubSteps < 2) {
            if (objSubSteps === 0 && repObjectives.length === 0) {
                return showToast('No objective selected', 'Pick objectives for your representative');
            }
            if (objSubSteps === 1 && !repMainGoal) {
                return showToast('No main objective selected', 'Pick the main goal for your representative');
            }
            return setObjSubSteps((prev) => prev + 1);
        }
    
        if (activeStep === 2) {
            for (const inst of repInstructions) {
                if (inst.answer.length === 0 || inst.question.length === 0) {
                    return showToast('There are missing fields', 'Fill all the questions and answers you created');
                }
            }
        }
    
        if (activeStep === 3) {
            for (const product of repProducts) {
                if (product.length === 0) {
                    return showToast('There are missing fields', 'Fill all the products you created');
                }
            }
        }
        if (activeStep < 3) {
            setActiveStep((activeStep) => activeStep + 1)
        } else {
            setOpenNewProject(false)
            toast({
                title: 'New project created!',
                description: "You may interact with your new project now",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            const masterAccessRef = doc(firestoreDb, 'utils', 'masterAccess')
            getDoc(masterAccessRef).then((masterAccess) => {
                const masterUids = masterAccess.exists() ? masterAccess.data()['uids'] : [];
                const projectsRef = collection(firestoreDb, 'campaigns')
                const payload = {
                    campaignName,
                    repName,
                    repManager,
                    dealName,
                    dealAddress,
                    dealTime,
                    dealCity,
                    dealState,
                    dealZip,
                    defaultProduct,
                    forwardNumber,
                    repVoice,
                    repObjectives,
                    repMainGoal,
                    autoDialerSchedule: autoDialerSchedule,
                    repInstructions,
                    repProducts,
                    createdAt: new Date().toISOString(),
                    createdBy: auth.currentUser!.uid,
                    active: false,
                    model: "gpt-4-1106-preview",
                    allowedAccess: Array.from(new Set([auth.currentUser!.uid, ...masterUids]))
                }
                addDoc(projectsRef, payload).then(() => {
                    setRepName('');
                    setDealName('');
                    setDealAddress('');
                    setDealTime({});
                    setDealCity('');
                    setDealState('');
                    setDealZip(undefined);
                    setAutoDialerSchedule(undefined);
                    setRepVoice('');
                    setRepObjectives([]);
                    setRepMainGoal(undefined);
                    setObjSubSteps(0);
                    setRepInstructions([]);
                    setRepProducts([]);
                    setRepManager('');
                    setDefaultProduct('');
                    setForwardNumber('');
                    setActiveStep(0);
                }).catch((error) => {
                    console.log(error)
                }
                )
        })
    }}
    
    function addObjective(obj:{name:string, emoji:string}){
        if (repObjectives.map((x) => x.emoji).includes(obj.emoji)){
            setRepObjectives((prev) => prev.filter(v => v.emoji !== obj.emoji))
        }else{
            setRepObjectives((prev) => [...prev, obj])
        }
    }

    function checkPrev(){
        if (activeStep  === 1 && objSubSteps > 0){
            return setObjSubSteps((prev) => prev - 1)
        }
        return setActiveStep((prev) => prev - 1);
    }

    function addRepQuestion(idx:number){
        let newRepObjectives = [...repObjectives];
        if (newRepObjectives[idx].questions){
            newRepObjectives[idx].questions?.push('')
        }else{
            newRepObjectives[idx].questions = [''];
        }
        setRepObjectives(newRepObjectives)
    }

    function editQuestion(idxObj:number, idxQ:number, message:string){
        let newRepObjectives = [...repObjectives];
        newRepObjectives[idxObj].questions![idxQ] = message;
        setRepObjectives(newRepObjectives)   

    }

    function deleteQuestion(idxObj:number, idxQ:number){
        let newRepObjectives = [...repObjectives];
        newRepObjectives[idxObj].questions = newRepObjectives[idxObj].questions?.filter((v,idx) => idx !== idxQ)
        setRepObjectives(newRepObjectives)   
    }

    function createNewDealership(){
        setLoading(true);
        fetchCheckDealershipName(newDealership).then((res) => {
            if (res.status === 'error'){
                toast({
                    title: 'Error',
                    description: "This dealership name is not available. Pick another name.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            } else if (res.status === 'success') {
                const userRef = doc(firestoreDb, 'users', auth.currentUser!.uid);
                getDoc(userRef)
                    .then((user) => {
                        const userData = user.data();
                        if (userData) {
                            const newDealershipOptions = [...userData.dealershipOptions, newDealership];
                            const payload = {
                                dealershipOptions: newDealershipOptions
                            };
                            updateDoc(userRef, payload)
                                .then(() => {
                                    setDealName(newDealership);
                                    setAddNewDealership(false);
                                    setNewDealership('');
                                })
                        }
                    }).catch(() => {
                        setLoading(false);
                    })
            }
            setLoading(false);})
    }

    return(
        <Modal size="2xl" isOpen={openNewProject} onClose={() => {setOpenNewProject(false)}}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                <Stepper colorScheme="teal" index={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                        />
                    </StepIndicator>
                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                    </Step>
                ))}
                </Stepper>
            </ModalHeader>
            {activeStep === 0 &&
            <ModalBody paddingLeft="4%" paddingRight="5%">
                <Input placeholder="Give your campaign a name" value={campaignName} onChange={(e) => {setCampaignName(e.target.value)}} />
                <Text marginTop="4%" fontSize="22px" fontWeight={200}>Let's create a representative...</Text>
                <Input value={repName} onChange={(e) => {setRepName(e.target.value)}} marginTop="3%" placeholder="Representative's name" />
                {!addNewDealership ?
                <Flex marginTop="4%" >
                    <Select value={dealName} onChange={(e) => {setDealName(e.target.value)}} placeholder="Pick dealership">
                        {dealershipOptions.map((dealership) => {
                            return <option value={dealership} key={dealership}>{dealership}</option>
                        })}
                    </Select>
                    <Tooltip label="Add new dealership">
                        <IconButton onClick={() => {setAddNewDealership(true)}} variant="outline" aria-label="Add dealership" marginLeft="2%" borderRadius="20px" icon={<AddIcon />} />
                    </Tooltip>
                </Flex>
                :
                <Flex marginTop="4%">
                    <Input border="2px solid #497fbb" value={newDealership} onChange={(e) => {setNewDealership(e.target.value)}} placeholder="Create new dealership" />
                    <Tooltip label="Back">
                        <IconButton isLoading={loading} colorScheme={newDealership.length === 0 ? "blue" : "green"} onClick={() => {newDealership.length === 0 ? setAddNewDealership(false) : createNewDealership();}} variant="outline" aria-label="Back" marginLeft="2%" borderRadius="20px" icon={newDealership.length === 0 ? <ArrowBackIcon /> : <CheckIcon />} />
                    </Tooltip>
                </Flex>
                }
                <Input value={repManager} onChange={(e) => {setRepManager(e.target.value)}} marginTop="4%" placeholder="Name of Representative's Manager" />
                <Flex justifyContent="space-between">
                    <Input w="67%" value={dealAddress} onChange={(e) => {setDealAddress(e.target.value)}} marginTop="5%" placeholder="Dealership address" />
                    <Input type="number" w="30%" value={dealZip} onChange={(e) => {if(e.target.value.length < 10) {setDealZip(e.target.value)}}} marginTop="5%" placeholder="Dealership zip" />
                </Flex>
                <Flex marginTop="4%" justifyContent="space-between">
                    <Input w="67%" value={dealCity} onChange={(e) => {setDealCity(e.target.value)}} placeholder="Dealership city" />
                    <Select w="30%" value={dealState} onChange={(e) => {setDealState(e.target.value)}}  placeholder="Dealership state">
                    {ALL_STATES.map((state:{value:string,label:string}) => {
                        return <option value={state.value} key={state.value}>{state.label}</option>
                    })}
                    </Select>
                </Flex>
                <Flex marginTop="4%">
                    <Input value={defaultProduct} onChange={(e) => {setDefaultProduct(e.target.value)}} placeholder="Default Product or Service" />
                    <Tooltip label="Each outbound call requires knowing the product/service in which the customer previously demonstrated interest. In the event that this information is not available, which product/service should we assume the customer is probably interested in?">
                        <QuestionIcon margin="auto" color="gray.400" marginLeft="2%" />
                    </Tooltip>
                </Flex>
                <Flex marginTop="4%">
                    <FormControl id="phone-number">
                        <FormLabel>
                            <Flex>
                                <Text>Forward number</Text>
                                <Tooltip label="In case a customer calls back to the phone number used in this campaign, to which number should we forward the call?">
                                    <QuestionIcon margin="auto" color="gray.400" marginLeft="1%" />
                                </Tooltip>
                            </Flex>
                        </FormLabel>
                        <PhoneInput
                            forceDialCode={true}
                            defaultCountry="us"
                            value={forwardNumber}
                            onChange={(phone) => setForwardNumber(phone)}
                            countrySelectorStyleProps={{buttonStyle: {background: 'transparent', height: '40px'}}}
                            inputStyle={{width: '100%', fontSize:"15px", background:"transparent", height: '40px'}}
                            countries={defaultCountries.filter((country) => {
                                const { iso2 } = parseCountry(country);
                                return ['us'].includes(iso2);
                            })}
                        />
                    </FormControl>
                </Flex>
                <DealershipOpeningHours dealTime={dealTime} onDealTimeChange={setDealTime} />
                <SelectVoice selected={repVoice} onSelect={setRepVoice} />
                <AutoDailerSchedule autoDialerSchedule={autoDialerSchedule} setAutoDialerSchedule={setAutoDialerSchedule} />
            </ModalBody>}
            {activeStep === 1 &&
            <ModalBody>
                <Box opacity={objSubSteps === 0 ? 1 : 0.5}>
                    <Text marginBottom="3%" fontSize="22px" fontWeight={200}>Let's set the objectives for the representative...</Text>
                    <Wrap spacing="12px">
                        {possibleObjectives.map((obj) => {
                            return <WrapItem><Tag colorScheme={repObjectives.map((x) => x.emoji).includes(obj.emoji) ? "green" : "gray"} onClick={() => {objSubSteps === 0 && addObjective(obj)}} cursor="pointer" borderRadius="20px" size="lg">{obj.emoji + ' ' + obj.name}</Tag></WrapItem>
                        })}
                    </Wrap>
                </Box>
                {objSubSteps >= 1 &&
                <SlideFade in={objSubSteps >= 1}>
                    <Box opacity={objSubSteps === 1 ? 1 : 0.5}>
                        <Divider borderColor="#c7c7c7" marginTop="3%" marginBottom="3%" />
                        <Text marginTop="3%" fontSize="22px" fontWeight={200}>Now let's decide what the main objective is...</Text>
                        <Text marginBottom="3%" fontSize="12px" fontWeight={500}>The difference between a main objective and an objective is that during the conversation the other goals may be dropped if they won't help reach the main goal</Text>
                        <Wrap spacing="12px">
                            {repObjectives.map((obj) => {
                                return <WrapItem><Tag colorScheme={obj === repMainGoal ? "blue" : "gray"} onClick={() => {objSubSteps === 1 && setRepMainGoal(obj)}} cursor="pointer" borderRadius="20px" size="lg">{obj.emoji + ' ' + obj.name}</Tag></WrapItem>
                            })}
                        </Wrap>
                    </Box>
                </SlideFade>
                }
                {objSubSteps === 2 &&
                <SlideFade in={objSubSteps === 2}>
                    <Box>
                        <Divider borderColor="#c7c7c7" marginTop="3%" marginBottom="3%" />
                        <Text  fontSize="22px" fontWeight={200}>What about customizing the questions? (optional)</Text>
                        <Text marginBottom="3%" fontSize="12px" fontWeight={500}>You may offer some examples showing how the questions should look like</Text>
                        {repObjectives.map((obj, idxObj) => {
                                return (
                                    <Box marginTop="1%">
                                        <Flex>
                                            <Text marginTop="auto" marginBottom="auto">{obj.emoji + ' ' + obj.name}</Text>
                                            <IconButton onClick={() => {addRepQuestion(idxObj)}} marginLeft="1%" marginTop="auto" marginBottom="auto" size="xs" variant="outline" borderRadius="20px" aria-label="Add question" icon={<AddIcon color="gray.500" />} />
                                        </Flex>
                                        {obj.questions && obj.questions.map((q, idxQ) => {
                                            return <Flex marginTop="1%" marginLeft="3%">
                                                        <Input borderColor="#c7c7c7" borderRadius="20px" value={q} onChange={(e) => {editQuestion(idxObj, idxQ, e.target.value)}} />
                                                        <IconButton onClick={() => {deleteQuestion(idxObj, idxQ)}} colorScheme="gray" marginTop="auto" marginBottom="auto" marginLeft='1%' borderRadius="20px" aria-label="Delete field" icon={<DeleteIcon />} size='sm' variant='outline' />
                                                    </Flex>
                                        })}
                                    </Box>
                                )
                            })}
                    </Box>
                </SlideFade>}
            </ModalBody>}
            {activeStep === 2 &&
            <ModalBody>
                <Text fontSize="22px" fontWeight={200}>What about adding specific instructions? (optional)</Text>
                <Text marginBottom="3%" fontSize="12px" fontWeight={500}>For a given question, you can program the representative's answer. You may edit this field later.</Text>
                {repInstructions.map((inst, idx) => {
                    return (
                        <Flex marginTop="4%">
                            <Box w="95%">
                                <Input onChange={(e) => {setRepInstructions((prev) => {const newRepInst = [...prev]; newRepInst[idx]= {...newRepInst[idx], question: e.target.value}; return newRepInst; })}} borderRadius="20px" placeholder="Question" value={inst.question} />
                                <Textarea marginTop="1%" borderRadius="10px" marginLeft="1%" w="98%"  placeholder="Answer" value={inst.answer} onChange={(e) => {setRepInstructions((prev) => {const newRepInst = [...prev]; newRepInst[idx]= {...newRepInst[idx], answer: e.target.value}; return newRepInst; })}} />
                            </Box>
                            <IconButton onClick={() => {setRepInstructions((prev) => prev.filter((_,i) => i !== idx))}} w="5%" colorScheme="gray" marginTop="auto" marginBottom="auto" marginLeft='1%' borderRadius="20px" aria-label="Delete field" icon={<DeleteIcon />} size='sm' variant='outline' />
                        </Flex>
                    )
                })}
            </ModalBody>}
            {activeStep === 3 &&
            <ModalBody>
                <Text fontSize="22px" fontWeight={200}>Let the representant know about your products (optional)</Text>
                <Text fontSize="12px" fontWeight={500}>Productive conversations require deep knowledge about the products you are selling. Add your inventory here so the representant can better assist the customers. You may edit this later.</Text>
                {repProducts.map((p, idx) => {
                    return (
                    <Flex>
                        <Textarea borderRadius="10px" marginTop="3%" rows={5} placeholder={newProductPlaceholder} value={p} onChange={(e) => {setRepProducts((prev) => {const newProducts = [...prev]; newProducts[idx] = e.target.value; return newProducts})}} />
                        <IconButton onClick={() => {setRepProducts((prev) => prev.filter((_,i) => i !== idx))}} w="5%" colorScheme="gray" marginTop="auto" marginBottom="auto" marginLeft='1%' borderRadius="20px" aria-label="Delete field" icon={<DeleteIcon />} size='sm' variant='outline' />
                    </Flex>
                    )
                })}
            </ModalBody>}
            <ModalFooter>
                {activeStep > 0 &&
                <Button onClick={() => {checkPrev(); }} variant="outline" leftIcon={<ArrowBackIcon />}>Previous</Button>}
                <Spacer />
                {activeStep === 2 && <Button onClick={() => {setRepInstructions((prev) => [...prev, {question: '', answer: ''}])}} variant="solid" colorScheme="teal" rightIcon={<AddIcon />}>Add Instruction</Button>}
                {activeStep === 3 && <Button onClick={() => {setRepProducts((prev) => [...prev, ''])}} variant="solid" colorScheme="teal" rightIcon={<AddIcon />}>Add Product</Button>}
                <Spacer />
                <Button onClick={() => {checkNext();}} variant="outline" rightIcon={activeStep < 3 ? <ArrowForwardIcon /> : <CheckIcon />}>{activeStep < 3 ? "Next" : "Finish"}</Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
    )
}