import { Box, Button, Center, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { ImLab } from "react-icons/im";
import { BsFillPersonFill, BsGear } from "react-icons/bs";
import { IoIosAnalytics } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestoreDb } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import PlaygroundPage from './Playground';
import ConfigsPage from './Configs';
import CustomersPage from './Customers';
import AnalyticsPage from './Analytics';
import { CampaignDataInterface } from '../utils/interfaces';
import { FaMicrophone } from 'react-icons/fa';
import RecordingsPage from './Recordings';
import Appointments from './Appointments';
import { CalendarIcon } from '@chakra-ui/icons';

const SidebarButton = ({ item, onClick, isSelected }) => (
    <Button
        paddingLeft="15%"
        marginTop="3%"
        bg={isSelected ? '#BEE3F8' : 'transparent'}
        onClick={onClick}
        marginLeft="-1%"
        leftIcon={item.icon}
        colorScheme='blue'
        color={isSelected ? '#3A6FF8' : "#383838"}
        fontWeight={300}
        variant="ghost"
    >
        {item.name}
    </Button>
);

export default function CampaignDashboardPage({ allCampaigns }: any) {
    const [selectedPage, setSelectedPage] = useState<number>(0);
    
    const location = useLocation();
    const campaignId = new URLSearchParams(location.search).get('id');
    const navigate = useNavigate();
    const [campaignData, setCampaignData] = useState<CampaignDataInterface>(allCampaigns?.[campaignId] || {});
    const [prevData, setPrevData] = useState<CampaignDataInterface>(allCampaigns?.[campaignId] || {});

    useEffect(() => {
        if (!auth.currentUser || !campaignId) return;
        const campaignRef = doc(firestoreDb, 'campaigns', campaignId);
        return onSnapshot(campaignRef, (doc) => {
            setPrevData(doc.data());
            setCampaignData(doc.data());
        }, (error) => {
            console.error('Error getting campaign data: ', error);
        });
    }, [campaignId]);

    const pages = [
        {
            name: 'Analytics',
            page: <AnalyticsPage campaignData={campaignData} campaignId={campaignId!} />,
            icon: <IoIosAnalytics />
        },
        {
            name: 'Appointments',
            page: <Appointments campaignId={campaignId!} />,
            icon: <CalendarIcon />
        },
        {
            name: 'Configurations',
            page: <ConfigsPage campaignData={campaignData} setCampaignData={setCampaignData} prevData={prevData} setPrevData={setPrevData} campaignId={campaignId!} />,
            icon: <BsGear />
        },
        {
            name: 'Customers',
            page: <CustomersPage campaignData={campaignData} campaignId={campaignId!} />,
            icon: <BsFillPersonFill />
        },
        {
            name: 'Playground',
            page: <PlaygroundPage campaignData={campaignData} setCampaignData={setCampaignData} prevData={prevData} setPrevData={setPrevData} campaignId={campaignId!} />,
            icon: <ImLab />
        },
        {
            name: 'Recordings',
            page: <RecordingsPage  campaignId={campaignId!} />,
            icon: <FaMicrophone />
        }
    ];

    return (
        <Flex>
            <Box w="250px" h="100vh" borderRight="1px solid #e6e6e6">
                <Center>
                    <Image cursor="pointer" onClick={() => navigate('/')} h="170px" src="logo.png" />
                </Center>
                <Divider w="90%" marginLeft="5%" />
                <Text fontSize="14px" color="gray.400" marginTop="10%" marginLeft="5%">Menu</Text>
                {pages.map((item, index) => (
                    <SidebarButton
                        key={item.name}
                        item={item}
                        onClick={() => setSelectedPage(index)}
                        isSelected={selectedPage === index}
                    />
                ))}
            </Box>
            {campaignData && campaignId && pages[selectedPage].page}
        </Flex>
    );
}
