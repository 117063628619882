import { DeleteIcon } from "@chakra-ui/icons";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Box, ModalBody, Center, Text, Flex, Textarea, IconButton, Button, Switch, Tooltip, ModalFooter, useToast, Tabs, TabList, Tab, Avatar } from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { firestoreDb } from "../firebase";
import { IoMdPaperPlane } from "react-icons/io";
import { BsStars } from "react-icons/bs";
import fetchGenerateAutomaticResponse from "../helpers/fetchGenerateAutomaticResponse";
import { ConfigPromptInterface } from "../utils/interfaces";
import { standardGuidelines } from "../utils/others";

export default function ConfigPrompt({ openConfigPrompt, setOpenConfigPrompt, campaignData, campaignId, setPrevData }: ConfigPromptInterface) {
    const [manualEdit, setManualEdit] = useState<boolean>(false);
    const [guidelines, setGuidelines] = useState<string[]>(standardGuidelines);
    const [tab, setTab] = useState<number>(0);
    const [script, setScript] = useState<{content:string, role:string}[]>([{"role": "user", "content": "Hello?"}]);
    const [editIndex, setEditIndex] = useState<number>(-1);
    const [textValue, setTextValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {
        if (campaignData && campaignData.promptTemplate) {
            setGuidelines(campaignData.promptTemplate.communicationGuidelines);
            setManualEdit(campaignData.promptTemplate.manual);
            if (campaignData.promptTemplate.script && campaignData.promptTemplate.script.length > 0) {
                setScript(campaignData.promptTemplate.script);
            }
        }

    }, [campaignData])

    const addGuideline = () => {
        const newGuidelines = [...guidelines];
        newGuidelines.push("");
        setGuidelines(newGuidelines);
    };

    const deleteGuideline = (index: number) => {
        const newGuidelines = [...guidelines];
        newGuidelines.splice(index, 1);
        setGuidelines(newGuidelines);
    };

    const updateGuideline = (index: number, value: string) => {
        const newGuidelines = [...guidelines];
        newGuidelines[index] = value;
        setGuidelines(newGuidelines);
    };

    function updateScript() {
        const newScript = [...script];
        if (editIndex === -1) {
            newScript.push({"role": script.length % 2 === 1 ? "assistant" : "user", "content": textValue});
            setScript(newScript);
        } else {
            newScript[editIndex].content = textValue;
            setScript(newScript);
            setEditIndex(-1);
        }
        setTextValue('');
    }

    function saveChanges() {
        const campaignRef = doc(firestoreDb, 'campaigns', campaignId);
        updateDoc(campaignRef, {
            promptTemplate: {
                communicationGuidelines: guidelines,
                manual: manualEdit,
                reviews: campaignData.promptTemplate && campaignData.promptTemplate.reviews ? campaignData.promptTemplate.reviews : [],
                script: script.length > 1 ? script : []
            }
        }).then(() => {
            setOpenConfigPrompt(false);
            setPrevData({...campaignData, promptTemplate: {communicationGuidelines: guidelines, manual: manualEdit, reviews: campaignData.promptTemplate && campaignData.promptTemplate.reviews ? campaignData.promptTemplate.reviews : [], script: script.length > 1 ? script : []}})
            toast({
                title: 'Changes saved',
                description: 'Your changes have been saved',
                status: 'success',
                duration: 5000,
                isClosable: true
            })
        })
    }

    function generateResponse() {
        setLoading(true);
        fetchGenerateAutomaticResponse(script, campaignId).then((response) => {
            setTextValue(response.response);
            setLoading(false);
        })
    }

    return (
        <Modal size="2xl" isOpen={openConfigPrompt} onClose={() => {setOpenConfigPrompt(false); setGuidelines(campaignData.promptTemplate && campaignData.promptTemplate.communicationGuidelines ? campaignData.promptTemplate.communicationGuidelines : standardGuidelines)}}>
            <ModalOverlay />
            <ModalContent borderRadius="10px">
                <ModalHeader borderTopRadius="10px" bg="blue.500" color="white" fontSize="lg" fontWeight="bold">
                    <Tabs onChange={(e) => {setTab(e)}} index={tab} size="md" variant="soft-rounded" w="95%" isFitted>
                        <TabList>
                            <Tab><Text color={tab === 0 ? "gray.700" : "whiteAlpha.900"}>Communication Guidelines</Text></Tab>
                            <Tab><Text color={tab === 1 ? "gray.700" : "whiteAlpha.900"}>Sample Script</Text></Tab>
                        </TabList>
                    </Tabs>                        
                </ModalHeader>
                <ModalCloseButton />
                {tab === 0 ?
                <ModalBody borderRadius="10px">
                    <Center>
                        <Text fontSize="14px" color="gray.600" w="95%">In this section you can check how the representative is prompted to behave. The reviews you provide are used to continuously improve its behavior during the call. You may also prompt it yourself by switching to manual editing, but note that the prompt won't benefit from feedback improvements from reviews anymore.</Text>
                    </Center>
                    <Center margin="2%">
                        <Text fontWeight={500}>Manual Editing</Text>
                        <Switch isChecked={manualEdit} onChange={(e) => {setManualEdit(e.target.checked)}} marginTop="auto" marginBottom="auto" size="md" colorScheme="green" marginLeft="2" />
                    </Center>
                    {guidelines.map((guideline, index) => {
                        return (
                            <Flex margin="2%" alignItems="center">
                                <Text color="blue.600" fontSize="xl" fontWeight={700}>{index + 1}.</Text>
                                <Textarea
                                    isDisabled={!manualEdit}
                                    marginLeft="1%"
                                    key={index}
                                    value={guideline}
                                    border="1px"
                                    borderColor="blue.200"
                                    bg="gray.50"
                                    p="2"
                                    onChange={(e) => {updateGuideline(index, e.target.value)}} />
                                <IconButton isDisabled={!manualEdit} aria-label="Delete" icon={<DeleteIcon />} onClick={() => {deleteGuideline(index)}} variant="ghost" />
                            </Flex>
                        )
                    })}
                    <Center mt="4">
                        <Button isDisabled={!manualEdit} colorScheme="blue" borderRadius="full" onClick={addGuideline}>+ Add guideline</Button>
                    </Center>
                </ModalBody>
                :
                <ModalBody borderRadius="10px">
                    <Center>
                        <Text fontSize="14px" color="gray.600" w="95%">In this section you may include a sample script in order to better guide the assistant. Create a fictitious conversation including the behavior you want the assistant to emulate.</Text>
                    </Center>
                    <Box
                    onClick={() => {if(editIndex >= 0) {setEditIndex(-1); setTextValue('')};}}
                    w="full"
                    overflowY="auto"
                    h="43vh"
                    padding="4"
                    marginTop="4"
                    border="1px solid gray"
                    borderRadius="md"
                    bg="gray.50"
                    >
                    {script.map((message, index) => (
                        <Flex
                        key={index}
                        justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
                        marginTop="2"
                        >
                        {message.role === 'assistant' && (
                            <Avatar
                            src="logo.ico"
                            size="sm"
                            marginRight="2"
                            />
                        )}
                        <Tooltip label="Click to edit">
                            <Box
                                onClick={() => {setEditIndex(index); setTextValue(message.content);}}
                                cursor="pointer"
                                bg={message.role === 'assistant' ? "blue.100" : "white"}
                                boxShadow="md"
                                border={editIndex === index ? "2px solid #8cbbf4" : ""}
                                borderRadius="lg"
                                padding="2"
                                maxWidth="70%"
                            >
                                <Text fontSize="sm" fontWeight="bold">
                                {message.role === 'user' ? "Customer" : "Assistant"}
                                </Text>
                                <Text color="gray.600" fontSize="xs">
                                {message.content}
                                </Text>
                                {editIndex === index && editIndex === script.length - 1 && <IconButton size="sm" aria-label="Delete" icon={<DeleteIcon />} onClick={() => {const newScript = [...script]; newScript.splice(index, 1); setScript(newScript);}} variant="ghost" />}
                            </Box>
                        </Tooltip>
                        {message.role === 'user' && <Avatar name="Customer" size="sm" marginLeft="2" bg="blue.100" />}
                        </Flex>
                    ))}
                    </Box>
                    <Flex>
                        {editIndex > -1 && <Avatar bg={editIndex % 2 === 0 ? "blue.100" : ""} marginTop="auto" marginBottom="auto" name={editIndex % 2 === 0 ? "Customer" : ""} src={editIndex % 2 === 1 ? "logo.ico" : ""} size="sm" marginRight="2" />}
                        <Textarea bg={editIndex > -1 ? "aliceblue" : ""} value={textValue} onChange={(e) => {setTextValue(e.target.value)}} borderRadius="10px" marginTop="2%" placeholder="Add a response to your sample script" />
                        <Flex direction="column" margin="auto" marginLeft="1%" marginRight= "1%" >
                            {textValue !== '' ? (
                            <IconButton onClick={() => {updateScript()}} aria-label="Add response" icon={<IoMdPaperPlane />} variant="outline" borderRadius="20px" />
                        ):(
                            <Tooltip label="Generate automatic response" hasArrow>
                                <IconButton isLoading={loading} onClick={() => {generateResponse()}} aria-label="Generate response" icon={<BsStars />} borderRadius="20px" colorScheme="purple" />
                            </Tooltip>
                        )}
                            
                        </Flex>
                    </Flex>
                </ModalBody>}
                <ModalFooter>
                    <Button variant='outline' colorScheme="blue" onClick={() => {saveChanges();}}>Save changes</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
