import { fetcher } from '../utils/api/fetcher';

export const createBilling = async ({
  userId,
  planId,
  card,
}:{
  userId: string,
  planId: string,
  card: { number: string; expiry: string; cvv: string }
}) => {
  const response = await fetcher('/billing', {
    method: "POST",
    body: JSON.stringify({
      user_id: userId,
      plan_id: planId,
      card: {
        number: card.number,
        expiration: card.expiry,
        code: card.cvv,
      },
    }),
  });
  if(!response.ok) {
    const error: any = new Error('An error occurred while creating billing.');
    error.info = await response.json(); // or use response.statusText
    error.status = response.status;
    throw error;
  }

  return response.json();
};
