import { fetcher } from '../utils/api/fetcher';

export default async function fetchCustomerPrompt(body:{name:string,product:string,details:string}) {
    const response = await fetcher('/create_customer_prompt', {
        method: 'POST',
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error('Failed to create customer prompt');
    }

    return response.json();
}
